export const initialState = {
  pages: [],
  currentPageIndex: 0,
  lastPageIndex: 0,
  responseArray: [],
  productsArray: null,
  wishlistArray: [],
  pending: false,
  error: false,
  success: false,
  productInfos: {},
  surveryResponseAnswers: {},
  showForm: false,
  showWishlist: false,
  loadingProducts: true,
  showWelcomePage: true, // change back
  showResultsPage: false, // debugging,
  resultOverviewExpandedMobile: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGES':
      console.log("dispatching set pages");
      return {
        ...state,
        pages: action.payload,
      };
      case 'SET_CURRENT_PAGE_INDEX':
      return {
        ...state,
        lastPageIndex: state.currentPageIndex,
        currentPageIndex: action.payload,
      };
    case 'SET_RESPONSE_ARRAY':
      return {
        ...state,
        responseArray: action.payload,
        loadingProducts: true,
      };
    case 'ADD_TO_WISHLIST_ARRAY':
      return {
        ...state,
        wishlistArray: [...state.wishlistArray, action.payload],
      };
    case 'REMOVE_FROM_WISHLIST_ARRAY':
      const newWishlist = [...state.wishlistArray];
      newWishlist.splice(newWishlist.findIndex((obj)=>{return obj.id == action.payload.id}), 1);
      return {
        ...state,
        wishlistArray: newWishlist,
      };
    case 'SET_PRODUCTS_ARRAY':
      return {
        ...state,
        productsArray: action.payload,
        loadingProducts: false,
      };
    case 'SET_AND_GO_TO_WISHLIST':
      console.log(" state from dispatch before",state);
        return {
          ...state,
        wishlistArray: action.payload.wishlist,
        showForm: false,
        showWelcomePage: false,
        showResultsPage: true,
        showWishlist: true,
        productsArray: action.payload.products,
        responseArray: [...action.payload.responses],
        loadingProducts: false,
        pages:  [...action.payload.pages],
      };
    case 'SEND_SURVEY_PENDING':
      return {
        ...state,
        pending: true,
        error: false,
        lastPageIndex: state.currentPageIndex,
        currentPageIndex: state.currentPageIndex + 1,
      };
    case 'SEND_SURVEY_ERROR':
      return {
        ...state,
        pending: false,
        error: true,
      };
    case 'SEND_SURVEY_SUCCESS':
      return {
        ...state,
        pending: false,
        error: false,
        success: true,
        surveryResponseAnswers: action.payload,
      };
    case 'GO_FROM_WELCOME_PAGE': {
      return {
        ...state,
        showWelcomePage: false,
        lastPageIndex: state.currentPageIndex,
      };
    }
    case 'GO_TO_WELCOME_PAGE': {
      return {
        ...state,
        showWelcomePage: true,
        lastPageIndex: state.currentPageIndex,
        currentPageIndex: 0,
      };
    }
    case 'GO_TO_FORM': {
      return {
        ...state,
        showForm: true,
        showWelcomePage: false,
        lastPageIndex: state.currentPageIndex,
        currentPageIndex: state.currentPageIndex + 1,
        productInfos: action.payload
      };
    }
    case 'GO_TO_RESULTS': {
      return {
        ...state,
        showForm: false,
        showWelcomePage: false,
        showResultsPage: true,
        lastPageIndex: state.currentPageIndex,
        currentPageIndex: state.currentPageIndex + 1,
      };
    }
    case 'GO_BACK_FORM':
      return {
        ...state,
        currentPageIndex: action.payload,
        showForm: false,
      };
    case 'SET_EXPAND_RESULT_OVERVIEW_MOBILE':
        return {
          ...state,
          resultOverviewExpandedMobile: action.payload,
        };
    case 'GO_BACK_TABLE':
      return {
        ...state,
        lastPageIndex: state.currentPageIndex,
        currentPageIndex: action.payload,
        success: false,
      };
  }
};
