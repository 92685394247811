import React, {useContext} from 'react';
import {GlobalContext} from '../../context';
import {priceFormatter} from '../../utils';

export const Result = ({goToContactForm, product, i, updateWishlist, checked, isWishList, afterFormSuccessUrl}) => {
    const {isDarkMode} = useContext(GlobalContext);
    const goToForm = () => {
        let cookieString = product.manufacturer.name;
        if (product.bulletPoints && product.bulletPoints[0]) {
            cookieString += ' ' + product.bulletPoints[0] + ' ' + product.pricesFrom + '€';
        }
        parent.postMessage({key: 'elements.user.heating_result', value: cookieString}, '*');
        goToContactForm({productid: product.id, systemType: product.type});
    }

    const mapType = (type) => {
        if (type === 'oel_b') {
            return 'Öl Bodenstehend';
        } else if (type === 'gas_w') {
            return 'Gas Wandhängend';
        } else if (type === 'gas_b') {
            return 'Gas Bodenstehend';
        } else {
            return 'Keine Angabe';
        }
    }

    return (
        <div key={i} className="results-item col-lg-4 col-sm-12">
            <div className={isDarkMode ? "results-item-container-dark" : "results-item-container"}>
                {/*                 {(i === 0 && !isWishList) && <div className="results-item-badge">
                    <span className="results-item-head-badge">Unsere Empfehlung</span>
                </div>}
                {(i === 1 && !isWishList) && <div className="results-item-badge">
                    <span className="results-item-head-badge">Bestseller</span>
                </div>}
                i === 0 || i === 1 ? 'results-item-head' :
                */}
                <div
                    className={'results-item-head results-item-head-standard'}
                    style={{'backgroundImage': 'url(https://portal.thermobox.de/Content/Images/' + product.b2c.productImage + ')'}}>
                    <span
                        className={isDarkMode ? "results-item-head-badge-dark" : "results-item-head-badge"}>{mapType(product.type)}</span>
                </div>
                <div className="results-item-content">
                    <div className="results-item-info">
                        <img src={'https://portal.thermobox.de/Content/Images/' + product.manufacturer.img}
                             alt={product.label}/>
                        <div className="results-item-info-label">{product.label}</div>
                        <div><strong>ab {priceFormatter.format(product.pricesFrom)}</strong></div>
                    </div>
                    <div className="results-item-spec">
                        <div className="results-item-features">

                            <div id={'accordion-' + product.id}>
                                {product.description.map((descriptionEntry, i) => {
                                    return (
                                        <div key={'description-' + product.id + '-' + i}
                                             className="card card-plain">
                                            <div className="card-header" role="tab"
                                                 id={'heading-' + product.id + '-' + i}>
                                                <a data-toggle="collapse" aria-expanded="false" className="collapsed"
                                                   aria-controls={'collapse-' + product.id + '-' + i}
                                                   data-target={'#collapse-' + product.id + '-' + i}>
                                                    <div
                                                        className="card-header-title"> {descriptionEntry.title}</div>
                                                    <i className="fa fa-chevron-down"/>
                                                    <i className="fa fa-chevron-up"/>
                                                </a>
                                            </div>
                                            <div id={'collapse-' + product.id + '-' + i}
                                                 className="collapse" role="tabpanel"
                                                 aria-labelledby={'heading-' + product.id + '-' + i}
                                                 data-parent={'#accordion-' + product.id}>
                                                <div className="card-body">
                                                    {descriptionEntry.bulletPoints && descriptionEntry.bulletPoints.length > 0 &&
                                                    <ul>
                                                        {descriptionEntry.bulletPoints.map((bulletPoint, i) => {
                                                            return (
                                                                <li className={isDarkMode ? "dark" : ""} key={i}>{bulletPoint}</li>)
                                                        })}
                                                    </ul>
                                                    }
                                                    {!descriptionEntry.bulletPoints &&
                                                    <span>{descriptionEntry.text}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                    <div className="results-item-footer">
                        <button className="buttonHighlight" onClick={() => goToForm()}>Jetzt kostenlos
                            anfragen
                        </button>
                        <div className={isDarkMode ? "dark custom-control custom-checkbox" : "custom-control custom-checkbox"}>
                            <input type="checkbox" className="custom-control-input" id={product.id + '-check'}
                                   checked={checked}
                                   name="example1" onChange={(e) => {
                                updateWishlist(product, e.target.checked);
                            }}/>
                            <label className="custom-control-label" htmlFor={product.id + '-check'}>Zur Merkliste
                                hinzufügen</label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
