import {ELEMENTS_SHOW_VERSION, IMAGE_ROOT_DIRECTORY} from "../../config";

const imageMapper = (iconValue) => {
    // default path
    let path = undefined;
    switch (iconValue) {
        case "BT1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/einfamilienhaus.jpg';
            break;
        case "BT2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/zweifamilienhaus.jpg';
            break;
        case "BT3":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/mehrfamilienhaus.jpg';
            break;
        case "BT4":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/wohnung.jpg';
            break;
        case "RT1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/freistehend.jpg';
            break;
        case "RT2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/reihenhaus.jpg';
            break;
        case "OR1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/eigentuemer.jpg';
            break;
        case "OR2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/mieter.jpg';
            break;
        case "REU1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/einfamilienhaus.jpg';
            break;
        case "REU2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/gewerblich.jpg';
            break;
        // case "LS1":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_1.svg';
        //     break;
        // case "LS2":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_2.svg';
        //     break;
        // case "LS3":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_3.svg';
        //     break;
        // case "LS4":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_4.svg';
        //     break;
        case "G":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/gas.jpg';
            break;
        case "O":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/oil.jpg';
            break;
        case "E":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/strom.jpg';
            break;
        // case "S":
        //     path = IMAGE_ROOT_DIRECTORY+'/page_images/weder-noch.jpg';
        //     break;
        case "P":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/pellets.jpg';
            break;
        case "LW":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/waermepumpe.jpg';
            break;
        case "PH":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/solarunterstuetzung.jpg';
            break;
        case "TOH1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/heizkoerper.jpg';
            break;
        case "TOH2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/fussbodenheizung.jpg';
            break;
        case "CH1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/dach.jpg';
            break;
        case "CH2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/in-der-wohnung.jpg';
            break;
        case "CH3":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/keller.jpg';
            break;
        case "WH1":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/heizungssystem.jpg';
            break;
        case "WH2":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/durchlauferhitzer.jpg';
            break;
        case "SO":
            path = IMAGE_ROOT_DIRECTORY+'/page_images/solarunterstuetzung.jpg';
            break;
        // case "PL1":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_kurzfristig.svg';
        //     break;
        // case "PL2":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_mittelfristig.svg';
        //     break;
        // case "PL3":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_langfristig.svg';
        // break;
        // case "BS":
        //     path = IMAGE_ROOT_DIRECTORY+'/visuals/sacksilo.svg';
        //     break;
        // case "PSR":
        //     path = IMAGE_ROOT_DIRECTORY+'/page_images/lagerraum.jpg';
        //     break;


        case "BTM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/gebaeude_art.svg';
            break;
        case "RTM":
            break;
        case "ORM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/besitz_verhaeltnis.svg';
            break;
        case "REU":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/nutzung.svg';
            break;
        case "LSM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/flaeche.svg';
            break;
        case "Y":
            path = IMAGE_ROOT_DIRECTORY+'/visuals/ja.svg';
            break;
        case "N":
            path = IMAGE_ROOT_DIRECTORY+'/visuals/nein.svg';
            break;
        case "EFS":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/heizart.svg';
            break;
        case "CHM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/aufstell_ort.svg';
            break;
        case "TOHM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/heizung.svg';
            break;
        case "WHM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/warmwasser_bereitung.svg';
            break;
        case "DTH":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/heizart.svg';
            break;
        case "PLM":
            path = IMAGE_ROOT_DIRECTORY+'/question_icons/zeitpunkt.svg';
            break;


        default:
            break;
    }
    return path;
}

export default imageMapper;
