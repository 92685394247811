import {ELEMENTS_SHOW_VERSION, IMAGE_ROOT_DIRECTORY} from "./config";

const Icons = {
    welcome_konfigurieren: '/gc_icons/konfigurieren.svg',
    welcome_angebot: '/gc_icons/angebot.svg',
    welcome_termin: '/gc_icons/termin.svg',
    welcome_montage: '/gc_icons/montieren.svg',
    custom_form_email: '/gc_icons/mail.svg',
    custom_form_phone: '/gc_icons/phone.svg',
    arrow_left: '/gc_icons/arrow_left.svg',
    arrow_right: '/gc_icons/arrow_right.svg',
    question_yes: '/visuals/ja.svg',
    question_no: '/visuals/nein.svg',
    question_3m: '/visuals/ENT_3m.svg',
    question_8m: '/visuals/ENT_8m.svg',
    question_14m: '/visuals/ENT_14m.svg',
    question_20m: '/visuals/ENT_20m.svg',
    info_dark: '/gc_icons/info-dark.svg',
    info_light: '/gc_icons/info.svg',
    edit: '/gc_icons/edit.svg',
    edit_dark: '/question_icons/edit_black.svg'
}

if (ELEMENTS_SHOW_VERSION) {
    for (const k of Array.from(Object.keys(Icons))) {
        Icons[k] = IMAGE_ROOT_DIRECTORY + Icons[k];
    }
}

export default Icons;