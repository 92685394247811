import React from 'react';

export const Loader = () => {
  return (
    <div className="loader">
      <div>
        <i className="far fa-clock fa-7x fa-spin" />
      </div>
      Warten Sie bitte
    </div>
  );
};
