import React, {createContext, useCallback, useEffect, useMemo, useReducer, useState,} from 'react';
import {initialState, reducer} from './reducer';
import {ThermoboxService} from './thermoboxService';
import {areConditionsTrue} from '../utils';
import {ELEMENTS_SHOW_VERSION, SHOW_CONTACT_FORM} from "../config";

const GlobalContext = createContext();

const GlobalContextProvider = ({children, config}) => {
  const [thermoboxService] = useState(() => new ThermoboxService(config));
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    afterFormSuccessUrl,
    isDarkMode,
    watchList
  } = config;

  const {
    currentPageIndex,
    responseArray,
    productInfos,
    pages,
    showWishlist,
    wishlistArray
  } = state;

  useEffect(() => {
    let productsCookie = '';
    wishlistArray.map(product => {
      if (productsCookie !== '') {
        if (product.bulletPoints && product.bulletPoints[0]) {
          productsCookie += ', ' + product.manufacturer.name + ' ' + product.bulletPoints[0] + ' ' + product.pricesFrom + '€';
        } else {
          productsCookie += ', ' + product.manufacturer.name + ' ' + product.label + ' ' + product.pricesFrom + '€';
        }
      } else {
        if (product.bulletPoints && product.bulletPoints[0]) {
          productsCookie += product.manufacturer.name + ' ' + product.bulletPoints[0] + ' ' + product.pricesFrom + '€';
        } else {
          productsCookie += product.manufacturer.name + ' ' + product.label + ' ' + product.pricesFrom + '€';
        }
      }
    })
    console.log('wishlist cookies', productsCookie);

    // set cookie
    // setCookie('elements.user.heating_results', JSON.stringify(productsCookie), 365);
    // set cookie iframe
    parent.postMessage({key: 'elements.user.heating_results_wishlist', value: productsCookie}, '*');

  }, [wishlistArray])

  const virtualPaginationSize = useMemo(() => pages.length, [pages]);
  const expandResultOverviewMobile = (expanded) => {
    dispatch({type: 'SET_EXPAND_RESULT_OVERVIEW_MOBILE', payload: expanded});
  }

  const fetchProductsAndOpenWishlist = useCallback(async (responses, selectedProducts) => {
    const products = await thermoboxService.fetchProducts(responses);
    // put selected products on wishlist
    let wishListArray = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      let match = products.find((obj) => {
        return obj.id == selectedProducts[i]
      });
      if (match != null) wishListArray.push(match);
    }
    console.log(wishListArray, responses, products);
    // if pages are not fetched yet, fetch them
    const pages = await thermoboxService.fetchPages();
    dispatch({
      type: 'SET_AND_GO_TO_WISHLIST',
      payload: {pages: pages, wishlist: wishListArray, products: products, responses: responses}
    });
  }, []);

  useEffect(() => {
    if (watchList != null && !showWishlist) {
      // user clicked link in email and wants to see his wishlist
      // get his response
      let responses = watchList.results;
      let selectedProducts = watchList.items;
      // fetch products for his responses
      fetchProductsAndOpenWishlist(responses, selectedProducts);
    } else {
      (async () => {
        console.log("im in here");
        const pages = await thermoboxService.fetchPages();
        console.log("pre dsipatching", pages);

        const pagesAndKeys = [];
        pages.map(page => {
          let answers = '';
          page.answers.map(answer => {
            answers += answer.label + ':' + answer.value + ','
          })

          pagesAndKeys.push({
            name: page.name,
            key: page.key,
            label: page.label,
            COOKIE_KEY: mapCookieName(page.key),
            answers: answers
          })
        })
        console.log('PAGES AND KEYS', pagesAndKeys);

        dispatch({type: 'SET_PAGES', payload: pages});
      })();
    }
  }, [watchList]);

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const fetchProducts = useCallback(async (responses) => {
    // parse response values first
    let parsedResponses = responses.map((response) => {
      if (Array.isArray(response.value)) {
        response.value = response.value.map((value) => {
          return parseValue(value);
        });
        return response;
      } else {
        response.value = parseValue(response.value);
        return response;
      }
    });
    const products = await thermoboxService.fetchProducts(parsedResponses);
    console.log('FETCHED PRODUCTS', products);

    let productsCookie = '';
    products.map(product => {
      if (productsCookie !== '') {
        if (product.bulletPoints && product.bulletPoints[0]) {
          productsCookie += ', ' + product.manufacturer.name + ' ' + product.bulletPoints[0] + ' ' + product.pricesFrom + '€';
        } else {
          productsCookie += ', ' + product.manufacturer.name + ' ' + product.label + ' ' + product.pricesFrom + '€';
        }
      } else {
        if (product.bulletPoints && product.bulletPoints[0]) {
          productsCookie += product.manufacturer.name + ' ' + product.bulletPoints[0] + ' ' + product.pricesFrom + '€';
        } else {
          productsCookie += product.manufacturer.name + ' ' + product.label + ' ' + product.pricesFrom + '€';
        }
      }
    })

    // set cookie
    // setCookie('elements.user.heating_results', JSON.stringify(productsCookie), 365);
    // set cookie iframe
    parent.postMessage({key: 'elements.user.heating_results', value: productsCookie}, '*');
    dispatch({type: 'SET_PRODUCTS_ARRAY', payload: products});
  }, []);

  const sendForm = useCallback(async (completeResponse) => {
    let results = await thermoboxService.sendForm(completeResponse);
    return results;
  }, []);

  const sendWatchlist = useCallback(async (responseArray, whishlistForm, whishlistItems) => {
    let results = await thermoboxService.sendWatchlist(responseArray, whishlistForm, whishlistItems);
    return results;
  }, []);

  const parseValue = (value) => {
    let val = null;
    // check if value is bool
    if (value == "true") {
      val = true;
    } else if (value == "false") {
      val = false;
    } else {
      // parse string to int
      let parsed = parseInt(value);
      if (isNaN(parsed)) {
        val = value
      } else val = parsed;
    }
    return val;
  }


  const handleSubmitForm = useCallback(async (form) => {
    // salutation mapping
    let salutation = 0;
    switch (form.salutation) {
      case 'herr':
        salutation = 1;
        break;
      case 'frau':
        salutation = 2;
        break;
      case 'none':
        salutation = 0;
        break;
    }
    // get installationTime
    let installationTime = responseArray.find((obj) => {
      return obj.key == 'umbauPlan';
    });
    if (installationTime) {
      installationTime = installationTime.value;
    } else {
      installationTime = '';
    }
    // create contact json
    let contactForm = {
      firstName: form.firstname,
      lastName: form.lastname,
      eMail: form.email,
      salutation: salutation,
      comment: form.note,
      phone: form.phone,
      installationTime: installationTime,
      callbackTime: form.callbackTime,

    };
    // put questions as object
    let questions = pages;
    let productid = productInfos.productid;
    let systemType = productInfos.systemType;

    // add response value to questions
    // loop over respones
    responseArray.map((response, i) => {
      let questionIndex = questions.findIndex((obj) => {
        return obj.key == response.key
      });
      if (questionIndex !== -1) {
        // TODO check for ARRAY
        if (Array.isArray(response.value)) {
          questions[questionIndex].value = response.value.map((value) => {
            return parseValue(value);
          })
        } else {
          // check if type is multiselect
          // if yes, value has to be an array even if only 1 values is selected
          if (questions[questionIndex].type === "iconMultiSelect") {
            questions[questionIndex].value = [parseValue(response.value)];
          } else {
            questions[questionIndex].value = parseValue(response.value);
          }
        }
        questions[questionIndex].show = true;
      }
    });
    console.log(responseArray);
    const completeResponse = {
      type: 0,// TODO where does this come from
      partner: 0,// TODO where does this come from
      contactForm: contactForm,
      questions: questions, // TODO change back
      productId: productid,
      systemType: systemType,
      tracking: {
        utm_source: 'sample string 1',
        utm_medium: 'sample string 2',
        utm_campaign: 'sample string 3',
        utm_term: 'sample string 4',
        utm_content: 'sample string 5',
      },
    };
    console.log('submitting contact form ', completeResponse);
    try {
      await sendForm(completeResponse);

      // redirect
      // window.location = afterFormSuccessUrl
      // redirect iframe
      if(window && window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({'redirect': afterFormSuccessUrl}));
      }

      if (window && window.parent && window.parent.postMessage && afterFormSuccessUrl) {
        window.parent.postMessage({'redirect': afterFormSuccessUrl}, '*');
      } else {
        console.log('Formular abgeschickt.');
      }

    } catch (e) {
      console.error(e);
    }
  }, [afterFormSuccessUrl, responseArray, productInfos]);

  const handleSelectAnswer = useCallback((value) => {
    const questionKey = pages[currentPageIndex].key;
    // get question type
    const questionType = pages[currentPageIndex].type;
    const questionRequired = pages[currentPageIndex].required;
    let currentResponse = {
      key: questionKey,
      value: value,
    };


    // let currentResponsetest = {
    //   key: questionKey,
    //   value: pages[currentPageIndex].answers.filter(el => {
    //     return parseInt(el.value) === parseInt(value)
    //   })[0].label,
    // };
    //
    // parent.postMessage(currentResponsetest, 'http://127.0.0.1:8080/');

    const responseArrayCopy = [...responseArray];
    // check if responseArray contains questionKey already
    const questionResponseIndex = responseArray.findIndex((obj) => {
      return obj.key == questionKey;
    });
    if (questionResponseIndex !== -1) {
      // check if answer is required
      if (questionRequired) {
        // contains key
        responseArrayCopy[questionResponseIndex] = selectAnswer(
            responseArrayCopy[questionResponseIndex], currentResponse,
            questionType);
      } else {
        // answer not required, so if answer value is the same as the selected one, remove from array
        switch (questionType) {
            // multi select, dont go to next page
          case 'iconMultiSelect': {
            // multiple values allowed, if the selected one is in array, remove it. if its the last element in array,
            // remove response array
            let oldResponseInArray = responseArrayCopy[questionResponseIndex];
            // currentResponse.value is array
            if (Array.isArray(oldResponseInArray.value)) {
              // is array, check if currentRespones value is already in array
              if (oldResponseInArray.value.includes(currentResponse.value)) {
                // if yes remove it
                oldResponseInArray.value.splice(
                    oldResponseInArray.value.indexOf(currentResponse.value), 1);
                // check if values is empty after removing, if yes, remove whole object from array
                if (oldResponseInArray.value.length == 0) {
                  responseArrayCopy.splice(
                      responseArrayCopy.indexOf(oldResponseInArray), 1);
                } else {
                  // update response Array
                  //responseArrayCopy[questionResponseIndex] = {...oldResponseInArray};
                }
              } else {
                // if val is not in array, push it
                oldResponseInArray.value.push(currentResponse.value);
                // update response Array
                responseArrayCopy[questionResponseIndex] = {...oldResponseInArray};
              }
            } else {  // currentResponse.value is single val
              // check if value is already equal to currentResponses value, if yes remove object from response array
              if (oldResponseInArray.value == currentResponse.value) {
                responseArrayCopy.splice(
                    responseArrayCopy.indexOf(oldResponseInArray), 1);
                break;
              }
              // if not , make array and put val in there
              let val = oldResponseInArray.value;
              oldResponseInArray.value = [val, currentResponse.value];
              // update response Array
              responseArrayCopy[questionResponseIndex] = {...oldResponseInArray};
            }
          }
            break;
            // single select, go to next page
          case 'iconSelect': {
            // one value allowed, if same value selected twice, de-select and remove from response array
            let oldResponseInArray = responseArrayCopy[questionResponseIndex];
            // check if value is already equal to currentResponses value, if yes remove object from response array
            if (oldResponseInArray.value == currentResponse.value) {
              responseArrayCopy.splice(
                  responseArrayCopy.indexOf(oldResponseInArray), 1);
              break;
            }
            // if not , make array and put val in there
            oldResponseInArray.value = currentResponse.value;
            // update response Array
            responseArrayCopy[questionResponseIndex] = {...oldResponseInArray};
          }
            break;
          case 'textInput': {
          }
            break;
          default:
            break;
        }
      }
    } else {
      // doesnt contain key, push it in (first time answering)
      // check for mulitselect, use array then
      if (questionType === 'iconMultiSelect') {
        currentResponse = {
          key: questionKey,
          value: [value],
        };
      }
      responseArrayCopy.push(
          selectAnswer(responseArrayCopy[questionResponseIndex],
              currentResponse, questionType));
    }
    dispatch({type: 'SET_RESPONSE_ARRAY', payload: responseArrayCopy});
    switch (questionType) {
        // multi select, dont go to next page
      case 'iconMultiSelect':
        break;
        // single select, go to next page
      case 'iconSelect':
        goToNextQuestion(null, responseArrayCopy);
        break;
      case 'textInput':
        goToNextQuestion(null, responseArrayCopy);
        break;
      default:
        break;
    }
  }, [currentPageIndex, responseArray, pages]);

  const selectAnswer = useCallback(
      (oldResponseInArray, currentResponse, questionType) => {
        if (!oldResponseInArray) { // not found in response array yet, take response object and put into array
          return currentResponse;
        } else {
          // answer exists already
          // check if multiple values allowed
          switch (questionType) {
              // multi select, add value to array of values
            case 'iconMultiSelect': {
              // add new value
              // check if value is array
              if (Array.isArray(oldResponseInArray.value)) {
                // is array, check if currentRespones value is already in array
                if (oldResponseInArray.value.includes(currentResponse.value)) {
                  // if yes remove it
                  oldResponseInArray.value.splice(
                      oldResponseInArray.value.indexOf(currentResponse.value),
                      1);
                } else {
                  // if val is not in array, push it
                  oldResponseInArray.value.push(currentResponse.value);
                }
              } else {
                // check if value is already equal to currentResponses value
                if (oldResponseInArray.value == currentResponse.value) {
                  oldResponseInArray.value = '';
                  break;
                }

                // if not , make array and put val in there
                let val = oldResponseInArray.value;
                oldResponseInArray.value = [val, currentResponse.value];
              }
            }
              break;
              // single select, go to next page
            case 'iconSelect': {
              oldResponseInArray.value = currentResponse.value;
            }
              break;
            case 'textInput': {
              oldResponseInArray.value = currentResponse.value;
            }
              break;
            default:
              break;
          }
        }
        return oldResponseInArray;
      }, []);


  const cookieNames = [
    'elements.user.size_of_heated_area',
    'elements.user.heating_elements',
    'elements.user.heating_water',
    'elements.user.building_type',
    'elements.user.technology_type',
    'elements.user.owner_or_tenant',
    'elements.user.house_type',
    'elements.user.private_or_commercial',
    'elements.user.current_energy_type',
    'elements.user.location_of_heating_system',
    'elements.user.interest_future_heating',
    'elements.user.heating_time',
    'elements.user.pellet.gas',
    'elements.user.pellet.oil',
    'elements.user.household_persons',
    'elements.user.heating_hybrid',
    'elements.user.house_year',
    'elements.user.house_renovation',
    'elements.user.location_of_heatpump',
    'elements.user.house_foundation',
    'elements.user.house_distance',
    'elements.user.heating_solar_additional',
    'elements.user.heating_results',
    'elements.user.heating_result'
  ]

  const mapCookieName =
      (key) => {
        console.log('KEY', key);
        if (key.startsWith('beheizteFlaeche')) {
          return 'elements.user.size_of_heated_area';
        }

        if (key.startsWith('b2cHeizkoerperart')) {
          return 'elements.user.heating_elements';
        }

        if (key.startsWith('artWarmwasserbereitung')) {
          return 'elements.user.heating_water';
        }

        switch (key) {
          case 'gebaeudeTyp':
            return 'elements.user.building_type';
          case 'energieTraeger':
            return 'elements.user.technology_type';
          case 'eigentumsVerhaeltnis':
            return 'elements.user.owner_or_tenant';
          case 'gebaeudeVermietet':
            return 'elements.user.house_type';
          case 'immobilienNutzung':
            return 'elements.user.private_or_commercial';
            // case 'beheizteFlaeche_type3':
            //   return 'elements.user.size_of_heated_area';
          case 'bestehendeBrennstoffversorgung':
            return 'elements.user.current_energy_type';
          case 'bestehendeBrennstoffversorgung_wohnung':
            return 'elements.user.current_energy_type';
          case 'schornstein':
            return 'elements.user.location_of_heating_system';
          case 'schornstein_mehrfamilienhaus':
            return 'elements.user.location_of_heating_system';
          case 'zukuenftigeBrennstoffversorgung':
            return 'elements.user.interest_future_heating';
          case 'zukuenftigeBrennstoffversorgung_mehrfamilienhaus':
            return 'elements.user.interest_future_heating';
          case 'umbauPlan':
            return 'elements.user.heating_time';
          case 'pelletLagerung_gas':
            return 'elements.user.pellet.gas';
          case 'pelletLagerung_oel':
            return 'elements.user.pellet.oil';
          case 'pvVerbrauchBasis':
            return 'elements.user.household_persons';
          case 'wpHybrid':
            return 'elements.user.heating_hybrid';
          case 'wpBaujahr':
            return 'elements.user.house_year';
          case 'wpSanierungStand':
            return 'elements.user.house_renovation';
          case 'aufstellungWP':
            return 'elements.user.location_of_heatpump';
          case 'untergrundWP':
            return 'elements.user.house_foundation';
          case 'entfernungWP':
            return 'elements.user.house_distance';
          case 'untergrundWPM':
            return 'elements.user.house_foundation';
          case 'entfernungWPM':
            return 'elements.user.house_distance';
          case 'zusatzSolar':
            return 'elements.user.heating_solar_additional';
            // case '':
            //   return 'elements.user.heating_results';
          default:
            return null;
        }
      }


  const goToNextQuestion = useCallback((pageIndex, currentResponseArray) => {
    console.log('TEEEEST', currentResponseArray);
    console.log('TEEEEST', pages[currentPageIndex]);

    for (let i = 0; i < currentResponseArray.length; i++) {
      //currentResponseArray[i]
      pages.map((page) => {
        if (page.key === currentResponseArray[i].key) {

          let value = '';

          if (mapCookieName(currentResponseArray[i].key)) {
            if (Array.isArray(currentResponseArray[i].value)) {
              console.log('ISARRAY');
              currentResponseArray[i].value.map(arrayValue => {
                const comma = value !== '' ? ', ' : '';
                const filteredValue = page.answers.filter(el => {
                  if (isNaN(parseInt(el.value))) {
                    return el.value === arrayValue
                  } else {
                    return parseInt(el.value) === parseInt(arrayValue)
                  }
                })
                value += comma;
                value += filteredValue[0] ? filteredValue[0].label : typeof arrayValue === 'string' ? arrayValue : filteredValue.label
              })
            } else {
              const filteredValue = page.answers.filter(el => {
                if (isNaN(parseInt(el.value))) {
                  return el.value === currentResponseArray[i].value
                } else {
                  return parseInt(el.value) === parseInt(currentResponseArray[i].value)
                }
              })

              if (currentResponseArray[i].key === 'bestehendeBrennstoffversorgung' && parseInt(currentResponseArray[i].value) === 4) {
                value = 'Strom'
              } else {
                value = filteredValue[0] ? filteredValue[0].label : typeof currentResponseArray[i].value === 'string' ? currentResponseArray[i].value : filteredValue.label
              }

              console.log('VALUUUE', value);
            }
            console.log('COOKIE', {key: mapCookieName(currentResponseArray[i].key), value: value});

            // set cookie
            // setCookie(mapCookieName(currentResponseArray[i].key), JSON.stringify(value), 365);

            // set cookie iframe
            parent.postMessage({
              key: mapCookieName(currentResponseArray[i].key),
              value: value
            }, '*');

          }


          // console.log('key', currentResponseArray[i].key);
          // console.log('value', value);


          // console.log(currentResponseArray[i].key, page.answers.filter(el => {
          //   return parseInt(el.value) === parseInt(currentResponseArray[i].value)
          // })[0].label)
        }
      })

    }

    // check if question is require and if something is selected
    if (!pageIndex) {
      if (pages[currentPageIndex].required == true) {
        // check if value is set
        let repsonseItem = currentResponseArray.find((obj) => {
          return obj.key == pages[currentPageIndex].key;
        });
        // check if value not empty
        if (!repsonseItem || repsonseItem.value.length < 1) {
          return;
        }
      }
    }
    let shouldRenderPage = true;
    let nextPageIndex = pageIndex ? pageIndex : (currentPageIndex + 1);
    // check if next page exists
    if (nextPageIndex < pages.length) {
      // check if next question is a condition and whether condition is true
      if (pages[nextPageIndex].conditions) {
        // has conditions
        shouldRenderPage = areConditionsTrue(pages[nextPageIndex].conditions,
            currentResponseArray);
      }
      if (!shouldRenderPage) {
        goToNextQuestion((nextPageIndex + 1), currentResponseArray);
        return;
      }

      // cnditoin check
      // wenn nicth anzeigen dann skip condigion
      dispatch({type: 'SET_CURRENT_PAGE_INDEX', payload: nextPageIndex}); // go next automatically
    } else {
      // sendSurvey();
      console.log('ende', responseArray);
      dispatch({type: 'GO_TO_RESULTS'});
    }
  }, [pages, currentPageIndex]);

  const handleGoToPage = (pageNr) => {
    dispatch({type: 'SET_CURRENT_PAGE_INDEX', payload: pageNr});
  }

  const updateWishlist = useCallback((product, checked) => {
    console.log(product, checked);
    if (!checked) {
      dispatch({type: 'REMOVE_FROM_WISHLIST_ARRAY', payload: product});

    } else {
      dispatch({type: 'ADD_TO_WISHLIST_ARRAY', payload: product});
    }

  }, [responseArray]);

  const goToContactForm = useCallback((productInfos) => {
    if (SHOW_CONTACT_FORM) {
      // should be getting product id and dispatch it
      dispatch({type: 'GO_TO_FORM', payload: productInfos});
    }

    // redirect
    // window.location = afterFormSuccessUrl

    // IN Elements show we go to a different url when clicking here.
    if (!SHOW_CONTACT_FORM) {
      // redirect iframe
      if (window.parent && window.parent.postMessage && afterFormSuccessUrl) {
        console.log(afterFormSuccessUrl)
        window.parent.postMessage({'redirect': afterFormSuccessUrl}, '*');
      }
    }

  }, []);

  const goToResults = useCallback(() => {
    dispatch({type: 'GO_TO_RESULTS'});
  }, []);

  const goToForm = useCallback(() => {
    dispatch({type: 'GO_TO_FORM'});
  }, []);

  const handleGoBack = useCallback((pageIndex) => {
    if (currentPageIndex == 0) {
      dispatch({type: 'GO_TO_WELCOME_PAGE', payload: currentPageIndex - 1});
    } else {
      let prevPage = typeof pageIndex !== "undefined" && pageIndex != null  ? pageIndex : currentPageIndex - 1;
      // check for conidtions
      // see if there are conditions for page to go back to
      if (pages[prevPage].conditions) {
        if (areConditionsTrue(pages[prevPage].conditions, responseArray)) {
          dispatch({type: 'SET_CURRENT_PAGE_INDEX', payload: prevPage});
        } else {
          // call this method again with new page to go to
          handleGoBack(prevPage - 1);
        }
      } else {
        // no conditions, just show it
        dispatch({type: 'SET_CURRENT_PAGE_INDEX', payload: prevPage});
      }

    }
  }, [currentPageIndex, responseArray, pages]);

  const clickStart = useCallback(() => {
    dispatch({type: 'GO_FROM_WELCOME_PAGE'});

    cookieNames.map(cookieName => {
      parent.postMessage({
        removeKey: cookieName,
        remove: true
      }, '*');
    })
  }, []);

  const handleUpdateResponses = useCallback((responses) => {
    dispatch({type: 'SET_RESPONSE_ARRAY', payload: responses});
  }, []);

  const contextValue = useMemo(() => {
    return {
      ...state,
      fetchProducts,
      handleGoBack,
      selectAnswer,
      handleSelectAnswer,
      goToResults,
      handleGoToPage,
      expandResultOverviewMobile,
      handleUpdateResponses,
      handleSubmitForm,
      goToContactForm,
      clickStart,
      goToForm,
      config,
      updateWishlist,
      sendWatchlist,
      goToNextQuestion,
      virtualPaginationSize,
      isDarkMode,
      watchList,
      showWishlist,
      fetchProductsAndOpenWishlist
    };
  }, [
    state,
    fetchProducts,
    goToForm,
    handleGoBack,
    selectAnswer,
    handleSelectAnswer,
    goToResults,
    handleGoToPage,
    expandResultOverviewMobile,
    handleUpdateResponses,
    handleSubmitForm,
    goToContactForm,
    clickStart,
    updateWishlist,
    sendWatchlist,
    config,
    goToNextQuestion,
    virtualPaginationSize,
    isDarkMode,
    watchList,
    showWishlist,
    fetchProductsAndOpenWishlist
  ]);

  return (
      <GlobalContext.Provider value={contextValue}>
        {children}
      </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
export {GlobalContextProvider, GlobalContext};
