import React, {useContext} from 'react';
import {GlobalContext} from '../../context';

const Footer = () => {

  const {config} = useContext(GlobalContext);

  const {imprintLink, agbLink, privacyLink} = config;

  return <div className="footer">
    <a href={imprintLink || agbLink}
       target="_blank">Impressum </a>
    |
    <a href={privacyLink}
       target="_blank"> Datenschutzerklärung</a>
  </div>;

};
export default Footer;
