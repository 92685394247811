import React, {useContext, useState} from 'react';
import SVG from 'react-inlinesvg';
import {GlobalContext} from '../../context';
import useStateWithCallback from 'use-state-with-callback';
import {areConditionsTrue, getUpdatedResponseArrayAddOrChangeValue, getUpdatedResponseArrayRemoveValue} from '../../utils';
import {ELEMENTS_SHOW_VERSION} from "../../config";
import iconMapper from "../IconMapper";
import Icons from "../../icons";


export const ResultOverview = ({responses, questionsArray, handleUpdateResponses, openOnMobile, isTabletOrMobile}) => {
   // set questions as state so we can change them later
   const [questions, setQuestions] = useState(questionsArray);
   // set current responses as state so we can change them later
   const [responseArray, setResponseArray] = useState(responses);
   const [collapsed, setCollapsed] = useStateWithCallback(true, (collapsed) => {
      // correct scrollheight by 300px bc of serverside resize
      setTimeout(function () {
         console.log("original height", document.getElementById("maincontainer").clientHeight, document.getElementById("maincontainer").scrollHeight, collapsed);
         let height = document.getElementById("maincontainer").clientHeight - 300;
         window.parent.postMessage({type: "iframe", message: "resize", height: height}, '*');
      }, 250);


   });
   const {isDarkMode} = useContext(GlobalContext);
   const renderHeaderItems = (items) => {
      let renderItems = [];
      let questionsInput = questions;
      let countItemsRendered = 0;
      items.forEach((item, key) => {
         // get question key
         let questionKey = item.key;
         // get question item
         let questionItem = questionsInput.find((obj) => {
            return obj.key === questionKey
         });
         // check if question has answers, if not skipt it for now
         // get icon from selected answer
         let answerValue = item.value; // will return array for multiple answers, just take first one
         if (Array.isArray(answerValue)) answerValue = item.value[0];
         let currentAnswerItem = questionItem.answers ?
             questionItem.answers.find((obj) => {
                return obj.value == answerValue
             }) : null;
         // only render "NUMBER" items , in this case 6
         if (currentAnswerItem === null || currentAnswerItem === undefined) return;
         let maxItems = 4;
         if (countItemsRendered > maxItems) return;
         countItemsRendered++;
         renderItems.push(
             <div key={key} className="resultOverviewHeaderItem d-flex flex-row">
                <SVG src={iconMapper(questionItem.icon)} className="resultOverviewHeaderItemIcon" width="12" height="12"/>
                <div className="resultOverviewHeaderItemText">{currentAnswerItem.label}</div>
             </div>
         )
      })
      return renderItems;
   }

   const renderHeaderItemsMobile = (items) => {
      let renderItems = [];
      let questionsInput = questions;
      let countItemsRendered = 0;
      items.forEach((item, key) => {
         // get question key
         let questionKey = item.key;
         // get question item
         let questionItem = questionsInput.find((obj) => {
            return obj.key === questionKey
         });
         // check if question has answers, if not skipt it for now
         // get icon from selected answer
         let answerValue = item.value; // will return array for multiple answers, just take first one
         if (Array.isArray(answerValue)) answerValue = item.value[0];
         let currentAnswerItem = questionItem.answers ?
             questionItem.answers.find((obj) => {
                return obj.value == answerValue
             }) : null;
         // only render "NUMBER" items , in this case 6
         if (currentAnswerItem === null || currentAnswerItem === undefined) return;
         let maxItems = 1;
         if (countItemsRendered > maxItems) return;
         countItemsRendered++;
         renderItems.push(
             <div key={key}
                  style={{marginLeft: '5px'}}>{currentAnswerItem.label}, </div>
         )
      });
      renderItems.push(
          <div style={{marginRight: '6px'}}>...</div>
      )
      return renderItems;
   }

   // change listener for question elements
   const handleChange = (selectedOption, page) => {
      let newOption = {
         key: page.key,
         value: selectedOption.value
      }
      setResponseArray(getUpdatedResponseArrayAddOrChangeValue(newOption, responseArray, questions));
   }


   const handleChangeSelectNothing = (pageToRemove) => {
      setResponseArray(getUpdatedResponseArrayRemoveValue(pageToRemove.key, responseArray, questions));
   }

   const handleChangeCheckbox = (event, newValue, page) => {
      let updatedResponseArray = [...responseArray];
      if (event.target.checked == true) {
         // checkbox is true now
         // check if repsonseArray contains response
         let index = responseArray.findIndex((obj) => {
            return obj.key == page.key
         });
         if (index == -1) {
            // value not in responses yet, create new response
            let newOption = {
               key: page.key,
               value: newValue

            };
            // if multiselect create array
            if (page.type == 'iconMultiSelect') {
               newOption = {
                  key: page.key,
                  value: [newValue]
               };
            }
            updatedResponseArray = getUpdatedResponseArrayAddOrChangeValue(newOption, responseArray, questions);
         } else {
            // response exists, check if current value is already in response
            let oldValues = responseArray[index].value;
            if (Array.isArray(oldValues)) {
               let indexSelectedOption = oldValues.indexOf(newValue);
               if (indexSelectedOption == -1) {
                  // option was not found in values, so push it
                  oldValues.push(newValue);
                  let newOption = {
                     key: page.key,
                     value: oldValues
                  };
                  updatedResponseArray = getUpdatedResponseArrayAddOrChangeValue(newOption, responseArray, questions);
               }
            } else {
               // no array, make array
               let oldValue = responseArray[index].value;
               newValue = [oldValue, newValue];
               let newOption = {
                  key: page.key,
                  value: newValue
               };
               updatedResponseArray = getUpdatedResponseArrayAddOrChangeValue(newOption, responseArray, questions);
            }
         }

      } else { // FALSE
         // checkbox false, remove value from responses
         let index = responseArray.findIndex((obj) => {
            return obj.key == page.key
         });
         if (index != -1) {
            // found in respones
            let values = responseArray[index].value;
            if (Array.isArray(values)) {
               let indexSelectedOption = values.findIndex((obj) => {
                  return obj.toString() == newValue.toString()
               });
               if (indexSelectedOption != -1) { // remove
                  // option was found in values, so remove it
                  values.splice(indexSelectedOption, 1);
                  // check if array is empty after removing value, if yes remove key from response
                  if (values.length < 1) {
                     // remove response from response array
                     updatedResponseArray = getUpdatedResponseArrayRemoveValue(page.key, responseArray, questions);
                  } else {
                     let newOption = {
                        key: page.key,
                        value: values
                     };
                     updatedResponseArray = getUpdatedResponseArrayAddOrChangeValue(newOption, responseArray, questions);
                  }
               }
            } else {
               // handle case for single in value
               if (values == newValue) {
                  // remove response from response array
                  updatedResponseArray = getUpdatedResponseArrayRemoveValue(page.key, responseArray, questions);
               }
            }
         }
      }
      setResponseArray(updatedResponseArray);
   };

   // check if value for page is in responseArray
   const isChecked = (value, page) => {
      if (page.answers) {
         let index = responseArray.findIndex((obj) => {
            return obj.key === page.key
         });
         if (index == -1) return false;
         let values = responseArray[index].value;
         // check if is array
         if (Array.isArray(values)) {
            let includes = values.findIndex((obj) => {
               return obj.toString() == value.toString()
            });
            //onsole.log(values, "from is checked values of response Array", values.includes(value));
            return includes == -1 ? false : true;

         } else {
            // only 1 value, see if they match
            if (values.toString() == value.toString()) return true;
            return false;
         }
      }
   }

   const getLabelForValue = (value, page) => {
      if (page.answers) {
         let answerItem = page.answers.find((obj) => {
            return obj.value.toString() == value.toString()
         });
         if (answerItem) {
            return answerItem.label;
         } else return "No Label";
      } else return "No Label";
   }

   const renderPages = (questions) => {
      let renderItems = [];
      questions.forEach((page, pageIndex) => {
         // render questions first
         let questionElement = null;
         // check for conditions
         let renderCondition = false;
         if (page.conditions && page.conditions.length > 0) {
            // for each condition
            renderCondition = areConditionsTrue(page.conditions, responseArray);
         }

         if (!page.conditions || renderCondition) {
            // get currentResponseElement for current Page
            let currentResponseElement = responseArray.find((obj) => {
               return obj.key == page.key
            });
            switch (page.type) {
               case "iconSelect": {
                  // try preselecting first answer for required questions
                  questionElement = (
                      <div className="select-container">
                        <span className="control" data-toggle="dropdown">
                           {currentResponseElement && getLabelForValue(currentResponseElement.value, page)}
                           {!currentResponseElement && !page.required && "Nichts ausgewählt"}
                           <i className="fa fa-chevron-down"/>

                        </span>
                         <ul className="dropdown-menu w-25">
                            {page.answers.map((option, index) => {
                               if (!currentResponseElement && page.required) {
                                  handleChange(option, page);
                                  return
                               }
                               ;
                               return (<a key={index} className="dropdown-item px-2" href="#"
                                          onClick={() => handleChange(option, page)}>

                                  {isChecked(option.value.toString(), page) &&
                                  <b> {getLabelForValue(option.value, page)}</b>}
                                  {!isChecked(option.value.toString(), page) && getLabelForValue(option.value, page)}
                               </a>)
                            })}
                            {!page.required && <a key={page.answers.length + 1} className="dropdown-item px-2" href="#"
                                                  onClick={() => handleChangeSelectNothing(page)}>
                               {!currentResponseElement && (<b>Nichts ausgewählt</b>)}
                               {currentResponseElement && ("Nichts ausgewählt")}
                            </a>}
                         </ul>

                      </div>);

               }
                  break;
               case "iconMultiSelect":
                  questionElement = (
                      <div key={pageIndex} className="resultOverviewCheckboxContainer">
                         {/*checkboxes*/
                            page.answers.map((option, index) => {
                               return (
                                   <div key={index} className="custom-checkbox-results custom-checkbox">
                                      <input type="checkbox" className="custom-control-input"
                                             id={"checkbox" + pageIndex + index}
                                             checked={isChecked(option.value.toString(), page)}
                                             name={"checkbox" + index}
                                             onChange={(event) => handleChangeCheckbox(event, option.value.toString(), page)}
                                      />
                                      <label className="custom-control-label" htmlFor={"checkbox" + pageIndex + index}>
                                         {option.label}</label>
                                   </div>
                               )
                            })}
                      </div>
                  )
                  break;
               case "textInput": {
                  questionElement = (<div className="select-container">
                     <span className="control" data-toggle="dropdown">
                        {page.min + " - " + page.max + page.suffix} <i className="fa fa-chevron-down"/>
                     </span>
                     <ul className="dropdown-menu w-25">
                        <a className="dropdown-item px-2" href="#">
                           {page.min + " - " + page.max + page.suffix}
                        </a>
                     </ul>

                  </div>)
                  break;
               }
               default:
                  break;
            }
            renderItems.push(<div key={pageIndex} className="resultOverviewPageItem">
               <div className="d-flex align-items-center">
                  <div>
                     <SVG src={iconMapper(page.icon)} className="resultOverviewHeaderItemIcon" width="14" height="14"/>
                  </div>
                  <div>
                     {page.name}
                  </div>
               </div>

               {questionElement}
            </div>);
         }
      });

      let nrCompleteRows = Math.floor(renderItems.length / 3);
      let remainingItems = renderItems.length % 3;
      let itemsRow0 = [];
      let itemsRow1 = [];
      let itemsRow2 = [];
      for (let i = 0; i < nrCompleteRows; i++) {
         itemsRow2.push(renderItems.pop());
      }
      // change array order first to last
      itemsRow2.reverse();
      for (let i = 0; i < nrCompleteRows; i++) {
         itemsRow1.push(renderItems.pop());
         if (i == (nrCompleteRows - 1) && remainingItems == 2) {
            itemsRow1.push(renderItems.pop());
            remainingItems--;
         }
      }
      // change array order first to last
      itemsRow1.reverse();
      for (let i = 0; i < nrCompleteRows; i++) {
         itemsRow0.push(renderItems.pop());
         if (i == (nrCompleteRows - 1) && remainingItems == 1) {
            itemsRow0.push(renderItems.pop());
            remainingItems--;
         }
      }
      // change array order first to last
      itemsRow0.reverse();

      let container = (
          <div className="d-flex w-100">
             <div className="resultOverviewExpandSection borderRight">
                {itemsRow0}
             </div>
             <div className="resultOverviewExpandSection borderRight">
                {itemsRow1}
             </div>
             <div className="resultOverviewExpandSection">
                {itemsRow2}
             </div>
          </div>
      )

      return container;


   }

   const renderPagesMobile = (questions) => {
      let renderItems = [];
      questions.forEach((page, pageIndex) => {
         // render questions first
         let questionElement = null;
         // check for conditions
         let renderCondition = false;
         if (page.conditions && page.conditions.length > 0) {
            renderCondition = areConditionsTrue(page.conditions, responseArray);
         }
         if (!page.conditions || renderCondition) {
            // get currentResponseElement for current Page
            let currentResponseElement = responseArray.find((obj) => {
               return obj.key == page.key
            });
            switch (page.type) {
               case "iconSelect": {
                  // try preselecting first answer for required questions
                  // if (!currentResponseElement && page.required) {return handleChange(page.answers[0].value, page)};
                  questionElement = (
                      <div className="select-container">
                        <span className="control" data-toggle="dropdown">
                           {currentResponseElement && getLabelForValue(currentResponseElement.value, page)}
                           {!currentResponseElement && !page.required && "Nichts ausgewählt"}
                           <i className="fa fa-chevron-down"/>

                        </span>
                         <ul className="dropdown-menu">
                            {page.answers.map((option, index) => {
                               if (!currentResponseElement && page.required) {
                                  handleChange(option, page);
                                  return
                               }
                               ;
                               return (<a key={index}
                                          style={{fontWeight: isChecked(option.value.toString(), page) ? "700" : "500"}}
                                          className="dropdown-item px-2" href="#"
                                          onClick={() => handleChange(option, page)}>

                                  {isChecked(option.value.toString(), page) && getLabelForValue(option.value, page)}
                                  {!isChecked(option.value.toString(), page) && getLabelForValue(option.value, page)}
                               </a>)
                            })}
                            {!page.required && <a key={page.answers.length + 1}
                                                  style={{fontWeight: currentResponseElement ? "500" : "700"}}
                                                  className="dropdown-item px-2" href="#"
                                                  onClick={() => handleChangeSelectNothing(page)}>
                               {!currentResponseElement && ("Nichts ausgewählt")}
                               {currentResponseElement && ("Nichts ausgewählt")}
                            </a>}
                         </ul>

                      </div>);

               }
                  break;
               case "iconMultiSelect":
                  questionElement = (
                      <div key={pageIndex} className="resultOverviewCheckboxContainer">
                         {/*checkboxes*/
                            page.answers.map((option, index) => {
                               return (
                                   <div key={index} className="custom-checkbox-results custom-checkbox">
                                      <input type="checkbox" className="custom-control-input"
                                             id={"checkbox" + pageIndex + index}
                                             checked={isChecked(option.value.toString(), page)}
                                             name={"checkbox" + index}
                                             onChange={(event) => handleChangeCheckbox(event, option.value.toString(), page)}
                                      />
                                      <label className="custom-control-label" htmlFor={"checkbox" + pageIndex + index}>
                                         {option.label}</label>
                                   </div>
                               )
                            })}
                      </div>
                  )
                  break;
               case "textInput": {
                  questionElement = (<div className="select-container">
                     <span className="control" data-toggle="dropdown">
                        {page.min + " - " + page.max + page.suffix} <i className="fa fa-chevron-down"/>
                     </span>
                     <ul className="dropdown-menu w-25">
                        <a className="dropdown-item px-2" href="#">
                           {page.min + " - " + page.max + page.suffix}
                        </a>
                     </ul>

                  </div>)
                  break;
               }
               default:
                  break;
            }
            renderItems.push(<div key={pageIndex} className="resultOverviewPageItem">
               <div className="d-flex align-items-center">
                  <div>
                     <SVG src={iconMapper(page.icon)} className="resultOverviewHeaderItemIcon" width="14" height="14"/>
                  </div>
                  <div>
                     <b>{page.name}</b>
                  </div>
               </div>

               {questionElement}
            </div>);
         }
      });
      let container = (
          <div className="d-flex w-100">
             <div className="resultOverviewExpandSectionMobile">
                {renderItems}
             </div>
          </div>
      )

      return container;

   }
   if (isTabletOrMobile) {
      return (
          <React.Fragment>
             <div className="resultOverviewContainerMobile">
                {collapsed &&
                (<div className={isDarkMode ? 'resultOverviewHeaderMobile darkMode' : 'resultOverviewHeaderMobile'}>
                   <div style={{
                      height: "15px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      color: "var(--primary-color)"
                   }}>
                      <div><b>Ihre Daten:</b></div>
                      {renderHeaderItemsMobile(responseArray)}
                      <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}} onClick={() => {
                         setCollapsed(!collapsed);
                         openOnMobile(true)
                      }}>
                         <div>
                            <span className="editButtonText"><b>Bearbeiten</b></span>
                         </div>
                         <div>
                            <SVG src={Icons.edit}
                                 className={isDarkMode ? 'editButtonIconMobile darkMode' : 'editButtonIconMobile'}
                                 width="10"
                                 height="10"/>
                         </div>
                      </div>
                   </div>
                </div>)}
                {!collapsed && (
                    <div className="resultOverviewHeaderMobileExpanded">
                       <div style={{height: "53px", display: "flex", alignItems: "center", width: "100%"}}>
                          <SVG src={Icons.edit} width="20.87" height="20.87" fill="white"/>
                          <span className="resultOverviewHeaderTextMobile">Angaben bearbeiten</span>
                       </div>
                    </div>)}
             </div>
             {!collapsed &&
             (<React.Fragment>
                <div className="resultOverviewExpandMobile">
                   {renderPagesMobile(questions)}
                </div>
                <div className="resultOverviewButtonContainerMobile">
                   <button className="toggleButtonMobile" onClick={() => {
                      setCollapsed(!collapsed);
                      openOnMobile(false)
                   }}>Abbrechen
                   </button>
                   <button className="toggleButtonMobile" onClick={() => {
                      setCollapsed(!collapsed);
                      handleUpdateResponses(responseArray);
                      openOnMobile(false)
                   }}>Angaben aktualisieren
                   </button>
                </div>
             </React.Fragment>)
             }
          </React.Fragment>)
   }

   return (
       <div className="resultOverviewContainer">
          <div className="resultOverviewHeader">
             {!collapsed && (
                 <React.Fragment> <SVG src={'select-containers/edit.svg'} className="editButtonIcon" width="20.87" height="20.87"/>
                    <span className="resultOverviewHeaderText">Angaben bearbeiten</span>
                 </React.Fragment>)}
             {collapsed && renderHeaderItems(responseArray)}
             {collapsed && <div className="editButton" onClick={() => setCollapsed(!collapsed)}>
                <div>
                   <SVG src={Icons.edit_dark} className="editButtonIcon" width="10"
                        height="10"/>
                </div>
                <div>
                   <span className="editButtonText">Ihre Angaben bearbeiten</span>
                </div>
             </div>}
             {!collapsed && <button className="toggleButton" onClick={() => {
                setCollapsed(!collapsed);
                handleUpdateResponses(responseArray)
             }}>Angaben aktualisieren</button>}
          </div>
          {!collapsed &&
          (<div className="resultOverviewExpand">
             {renderPages(questions)}
          </div>)}
       </div>
   );
}
