import React from 'react';
import SVG from 'react-inlinesvg';

import DatePicker from 'react-datepicker';
import {GlobalContext} from '../../context';
import Icons from "../../icons";
import {ELEMENTS_SHOW_VERSION, CONTACT_BY_FACHHANDWERKER_POSSIBLE} from "../../config";
import "react-datepicker/dist/react-datepicker.css";

export class CustomForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gender: 'frau',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      eigentum: '',
      fhw: '',
      note: '',
      isInvalidGender: false,
      useMail: true,
      phoneDate: new Date(),
      daten: false,
      agb: false,
      callTimeStart: '',
      callTimeEnd: '',
      formSent: false,
      timePickerValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    };

  }

  componentDidMount() {
    setTimeout(function () {
      window.requestAnimationFrame(function () {
        console.log("original height", document.getElementById("maincontainer").clientHeight, document.getElementById("maincontainer").scrollHeight);
        let height = document.getElementById("maincontainer").clientHeight - 300;
        window.parent.postMessage({type: "iframe", message: "resize", height: height}, '*');
        window.scrollTo(0,0);
        if (ELEMENTS_SHOW_VERSION) {
          window.parent.postMessage({type: "iframe", message: "scrollTo", positionx: 0, positiony: 0}, '*');
        }

      });

    }, 300);


    //
  }


  handleSubmit = (e) => {
    e.preventDefault();
    // also validate fields here
    if (this.state.gender === 'DEFAULT') {
      this.setState({isInvalidGender: true});
    } else {
      // make phone appointment string
      let phoneAppointment = "";
      if (this.state.phone !== '' && this.state.phoneDate !== '' && this.state.callTimeStart !== '' && this.state.callTimeEnd !== '') {
        phoneAppointment = this.state.phoneDate.toString() + " von " + this.state.callTimeStart.toString() + " bis " + this.state.callTimeEnd.toString();
      }
      console.log(phoneAppointment);
      const contact = {
        salutation: this.state.gender,
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        eigentum: this.state.eigentum,
        fhw: this.state.fhw,
        note: this.state.note,
        callbackTime: phoneAppointment
      };

      this.props.handleSubmitForm(contact);
      this.setState({formSent: true});
    }
  };


  handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    const value = e.target.value;

    this.setState({...this.state, [e.target.name]: value});
    console.log(this.state);
  };


  handleChecked = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    const value = e.target.checked;

    this.setState({...this.state, [e.target.name]: value});
    console.log(this.state);
  };
  handleChangePhoneDate = (e) => {
    console.log(e);

    this.setState({...this.state, ['phoneDate']: e});
    console.log(this.state);
  };

  handleChangeGender = (e) => {
    const value = e.target.value;

    this.setState({...this.state, gender: value, isInvalidGender: false});
  };

  handleChangeEigentum = (e) => {
    const value = e.target.value;

    this.setState({...this.state, eigentum: value});
  };

  changeUseMail = (e, value) => {
    e.preventDefault();
    this.setState({useMail: value})
  }

  canSubmit = () => {
    const normalFields = this.state.gender !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.note !== '';
    // email regex
    var re = /\S+@\S+\.\S+/;
    const contactFields = (this.state.useMail ? this.state.email !== '' && re.test(this.state.email) :
        this.state.phone !== '' && this.state.phoneDate !== '' && this.state.callTimeStart !== '' && this.state.callTimeEnd !== '');
    // const checkboxes = this.state.agb && this.state.daten;
    const checkboxes = this.state.daten;
    return normalFields && contactFields && checkboxes && this.state.eigentum !== 'miete';
  }

  render() {
    return (
        <>
          <div className="welcome text-center">
            <div className="top">
              <div className="title">Angebot anfragen</div>
              <div className="headline">Sie interessieren sich für eine Heizungsanlage? Wir kontaktieren Sie gerne, um
                weitere Fragen zu klären. Bitte füllen Sie die mit einem * gekennzeichneten Felder aus.
              </div>
            </div>
          </div>

          <form className="form-group container-fluid" onSubmit={this.handleSubmit}>
            <div className="row">
              {/*left*/}
              <div className="col-md-6 col-12">

                <div className="row form-group">
                  <legend>Anrede*</legend>
                  <div className="d-flex flex-wrap">

                    <div
                        className={this.context.isDarkMode ? "dark custom-control custom-radio mr-2 mr-md-3" : "custom-control custom-radio mr-2 mr-md-3"}>
                      <input className="custom-control-input" type="radio" name="genderRadios" id="genderRadio1"
                             value="frau"
                             checked={this.state.gender === "frau"}
                             onChange={e => this.handleChangeGender(e)}/>
                      <label className="custom-control-label" htmlFor="genderRadio1">
                        Frau
                      </label>
                    </div>
                    <div
                        className={this.context.isDarkMode ? "dark custom-control custom-radio mr-2 mr-md-3" : "custom-control custom-radio mr-2 mr-md-3"}>
                      <input className="custom-control-input" type="radio" name="genderRadios" id="genderRadio2"
                             checked={this.state.gender === "herr"}
                             value="herr"
                             onChange={e => this.handleChangeGender(e)}/>
                      <label className="custom-control-label" htmlFor="genderRadio2">
                        Herr
                      </label>
                    </div>
                    <div
                        className={this.context.isDarkMode ? "dark custom-control custom-radio mr-2 mr-md-3 mt-2 mt-md-0" : "custom-control custom-radio mr-2 mr-md-3 mt-2 mt-md-0"}>
                      <input className="custom-control-input" type="radio" name="genderRadios" id="genderRadio3"
                             checked={this.state.gender === "none"}
                             value="none"
                             onChange={e => this.handleChangeGender(e)}/>
                      <label className="custom-control-label" htmlFor="genderRadio3">
                        Keine Angabe
                      </label>
                    </div>

                  </div>
                </div>

                <div className="row form-group">
                  <legend>Vorname*</legend>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Vorname eingeben"
                      value={this.state.firstName}
                      name="firstName"
                      required
                      onChange={this.handleChange}
                  />
                </div>
                <div className="row form-group">
                  <legend>Nachname*</legend>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Nachname eingeben"
                      value={this.state.lastName}
                      name="lastName"
                      required
                      onChange={this.handleChange}
                  />
                </div>

              </div>


              {/*right*/}
              <div className="col-md-6 col-12">

                <div className="row form-group">
                  <legend>Kontaktieren Sie mich per*</legend>
                  <div>
                    <button className={(this.state.useMail ? '' : 'notselected') + ' icon-button'}
                            onClick={(e) => this.changeUseMail(e, true)}>
                      <SVG src={Icons.custom_form_email} width="20" height="20"/>
                      E-Mail*
                    </button>
                    <button className={(!this.state.useMail ? '' : 'notselected') + ' icon-button'}
                            onClick={(e) => this.changeUseMail(e, false)}>
                      <SVG src={Icons.custom_form_phone} width="20" height="20"/>
                      Telefon
                    </button>
                  </div>
                </div>

                {this.state.useMail && <div className="row form-group">
                  <legend>E-Mail*</legend>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="E-Mail-Adresse eingeben"
                      value={this.state.email}
                      name="email"
                      required
                      onChange={this.handleChange}
                  />
                </div>}

                {!this.state.useMail && <div className="row form-group">
                  <legend>Telefon*</legend>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Telefonnummer eingeben"
                      value={this.state.phone}
                      name="phone"
                      required
                      onChange={this.handleChange}
                  />
                </div>}

                {!this.state.useMail && <div className="row form-group">
                  <legend className="datepicker-legend">Rückrufzeitraum: 0-24 Uhr*</legend>
                  <DatePicker
                      selected={this.state.phoneDate}
                      name="phoneDate"
                      onChange={this.handleChangePhoneDate}
                      minDate={new Date()}
                  />

                </div>}

                {!this.state.useMail && <div className="row form-group time-picker">
                  <select
                      className={this.state.isInvalidGender ? 'form-control is-invalid' : 'form-control' + ' col-md-5'}
                      id="exampleFormControlSelect1"
                      onChange={this.handleChange}
                      value={this.state.callTimeStart}
                      name="callTimeStart"
                      required
                  >
                    {this.state.timePickerValues.map(val => {
                      return (
                          <option key={val}
                                  value={val}>{(val.toString().length === 1 ? '0' + val : val) + ':00'}</option>)
                    })}
                  </select>
                  bis
                  <select
                      className={(this.state.isInvalidGender ? 'form-control is-invalid' : 'form-control') + ' col-md-5 call-time-end-select'}
                      id="exampleFormControlSelect1"
                      onChange={this.handleChange}
                      value={this.state.callTimeEnd}
                      name="callTimeEnd"
                      required
                  >
                    {this.state.timePickerValues.map(val => {
                      return (
                          <option key={val}
                                  value={val}>{(val.toString().length === 1 ? '0' + val : val) + ':00'}</option>)
                    })}
                  </select>

                </div>}

              </div>


              <div className="col-md-12 col-12">
                <div className="row form-group">
                  <legend>Ich wohne...*</legend>
                  <div className="d-flex flex-wrap">

                    <div
                        className={this.context.isDarkMode ? "dark custom-control custom-radio mr-2 mr-md-3" : "custom-control custom-radio mr-2 mr-md-3"}>
                      <input className="custom-control-input" type="radio" name="eigentumRadios"
                             id="eigentumRadio1"
                             value="eigenheim"
                             checked={this.state.eigentum === "eigenheim"}
                             onChange={e => this.handleChangeEigentum(e)}/>
                      <label className="custom-control-label" htmlFor="eigentumRadio1">
                        in einem Eigenheim
                      </label>
                    </div>
                    <div
                        className={this.context.isDarkMode ? "dark custom-control custom-radio mr-2 mr-md-3" : "custom-control custom-radio mr-2 mr-md-3"}>
                      <input className="custom-control-input" type="radio" name="eigentumRadios"
                             id="eigentumRadio2"
                             checked={this.state.eigentum === "miete"}
                             value="miete"
                             onChange={e => this.handleChangeEigentum(e)}/>
                      <label className="custom-control-label" htmlFor="eigentumRadio2">
                        zur Miete
                      </label>
                    </div>
                  </div>

                </div>
              </div>


              {this.state.eigentum === 'miete' &&
              <div
                  style={{padding: 20}}> Vielen Dank für Ihr Interesse! Bei Mietobjekten ist der Vermieter für Ihre
                Heizung zuständig. Daher
                empfehlen wir, dass sich Ihr Vermieter direkt mit uns in Verbindung setzt. Bei Rückfragen stehen wir
                gern unter <span
                    style={{color: 'var(--highlight-color-1)', fontWeight: 'bold'}}>+49 421 408 933 51</span> zur
                Verfügung!
              </div>
              }

              {CONTACT_BY_FACHHANDWERKER_POSSIBLE &&
              <div className="col-md-12 col-12">
                <div className="row form-group">
                  <legend>Fachhandwerker</legend>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Falls vorhanden: Name und Ort meines Fachhandwerkers/Bauträgers/Planers"
                      value={this.state.fhw}
                      name="fhw"
                      onChange={this.handleChange}
                  />
                </div>
              </div>}

              <div className="col-md-12 col-12">
                <div className="row form-group">
                  <legend>Anmerkungen*</legend>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="10"
                            onChange={this.handleChange}
                            name="note"
                            placeholder="Geben Sie hier ihre Anmerkungen ein"
                            value={this.state.note}/>

                </div>
              </div>
              {this.state.formSent && (
                  <div className="col-12">
                    <div className="w-100 text-center py-3">
                      Vielen Dank für Ihre Anfrage, Ihr zuständiger Handwerker wird sich bei Ihnen melden!
                    </div>
                  </div>
              )}
              {/*<div className="col-md-6 offset-md-3 col-12">*/}
              {/*  <div className="custom-checkbox-results custom-checkbox  d-block">*/}
              {/*    <input type="checkbox" className="custom-control-input"*/}
              {/*           id={"checkbox1"}*/}
              {/*           checked={this.state.agb}*/}
              {/*           name="agb"*/}
              {/*           onChange={(e) => this.handleChecked(e)}*/}
              {/*    />*/}
              {/*    <label className="custom-control-label" htmlFor={"checkbox1"}>*/}
              {/*      Ich akzeptiere die <a href={this.context.config.agbLink} target="_blank">Allgemeinen*/}
              {/*      Geschäftsbedingungen</a>*</label>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-md-6 offset-md-3 col-12 ">
                <div className="custom-checkbox-results custom-checkbox d-block">
                  <input type="checkbox" className="custom-control-input"
                         id={"checkbox2"}
                         checked={this.state.daten}
                         name="daten"
                         onChange={(e) => this.handleChecked(e)}
                  />
                  <label className="custom-control-label" htmlFor={"checkbox2"}>
                    Ich akzeptiere die <a href={this.context.config.privacyLink}
                                          target="_blank">Datenschutzbedingungen</a>*</label>
                </div>
              </div>


              <div className="form-buttons col-md-12 col-12 text-center">
                <button className="button col-md-3 col-sm-12" onClick={this.props.handleBackToResults}>
                  Zurück zu den Ergebnissen
                </button>
                <button disabled={!this.canSubmit()} type="submit" className="buttonHighlight col-12 col-md-3 col-sm-12"
                        onClick={(e) => this.handleSubmit(e)}>
                  Kostenloses Angebot anfordern
                </button>
              </div>
            </div>
          </form>
        </>
    )
  }
}

CustomForm.contextType = GlobalContext;
