import { useContext } from "react";
import { GlobalContext } from './context';
export default function resolveIconPath(iconPath) {
    // none available yet
    switch (iconPath) {
        default:
            return './visuals/Icon_konfigurator_sonstiges.svg';
            break;
    }
}

export const areConditionsTrue = (conditionsArray, currentResponseArray) => {
    // multiple conditions all AND connected, so everything has to be true 
    let conditionResult = true;
    // loop over 
    for (let numCond = 0; numCond < conditionsArray.length; numCond++) {
        let currentCondResult = isConditionTrue(conditionsArray[numCond], currentResponseArray);
        conditionResult = conditionResult && currentCondResult;
    }
    return conditionResult;

};

export const getUpdatedResponseArrayAddOrChangeValue = (responseValueChange, currentResponseArray,questions) => {
    // get anwers for option to change 
    let index = currentResponseArray.findIndex((obj) => {
        return obj.key === responseValueChange.key
    });
    // get label for value and set in response array
    let updatedResponseArray = [];
    let tempResponseArray = [...currentResponseArray];
    if (index == -1) {
        // value not in responses yet
        tempResponseArray.push(responseValueChange);
        updatedResponseArray = [...tempResponseArray];
    } else {
        updatedResponseArray = [...tempResponseArray.slice(0, index),
        {
            ...tempResponseArray[index],
            ...responseValueChange,
        },
        ...tempResponseArray.slice(index + 1)]
    }
    // get all questions that are affected by this
    let questionsAffected = getQuestionsAffectedByChange(responseValueChange.key,questions);
    // recheck conditions with new resposne Values for affected questions
    for (let i = 0; i < questionsAffected.length; i++) {
        if (!areConditionsTrue(questionsAffected[i].conditions, updatedResponseArray)) {
            // conditions are not true anymore, remove response from responseArray
            let relatedResponseElementIndex = updatedResponseArray.findIndex((obj) => {
                return obj.key === questionsAffected[i].key
            });
            if (relatedResponseElementIndex != -1) {
                // responses contained affected question, remove it 
                updatedResponseArray.splice(relatedResponseElementIndex, 1);
            }
        }
    }
    console.log(updatedResponseArray);
    return updatedResponseArray;
}

export const getUpdatedResponseArrayRemoveValue = (valueToRemoveKey, currentResponseArray,questions) => {
    // get anwers for option to change 
    let tempResponseArray = [...currentResponseArray];
    let index = tempResponseArray.findIndex((obj) => {
        return obj.key === valueToRemoveKey;
    });
    // get label for value and set in response array
    if (index != -1) {
        // remove value from response Array
        tempResponseArray.splice(index,1);
        // get all questions that are affected by this
        let questionsAffected = getQuestionsAffectedByChange(valueToRemoveKey,questions);
        // recheck conditions with new resposne Values for affected questions
        for (let i = 0; i < questionsAffected.length; i++) {
            if (!areConditionsTrue(questionsAffected[i].conditions, tempResponseArray)) {
                // conditions are not true anymore, remove response from responseArray
                let relatedResponseElementIndex = tempResponseArray.findIndex((obj) => {
                    return obj.key === questionsAffected[i].key
                });
                if (relatedResponseElementIndex != -1) {
                    // responses contained affected question, remove it 
                    tempResponseArray = getUpdatedResponseArrayRemoveValue(questionsAffected[i].key,tempResponseArray,questions);

                }
            }
        }
    }
    let updatedResponseArray = [...tempResponseArray];
    console.log(updatedResponseArray);
    return updatedResponseArray;
}

export const isConditionTrue = (condition, currentResponseArray) => {
    // for now , arrays are not supportet so take first element
    let cond = condition;
    let renderCondition = false;
    // get element from current responses that condition is related to
    let responseElement = currentResponseArray.find((obj) => {
        return obj.key === cond.questionKey;
    });
    // check if condition is true
    if (responseElement) {
        // get operator
        let op = cond.operator;
        switch (op) {
            case '=': {
                let compVal = Array.isArray(cond.value) ? cond.value[0] : cond.value;
                let resVal = Array.isArray(responseElement.value) ? responseElement.value[0] : responseElement.value;
                if (resVal == compVal) {
                    renderCondition = true;
                    break;
                } else break;
            }
            case 'contains': {
                //loop over possible values for condition
                for (let c = 0; c < cond.value.length; c++) {
                    let val = cond.value[c];
                    // check if is array
                    if (Array.isArray(responseElement.value)) {
                        for (let i = 0; i < responseElement.value.length; i++) {
                            if (responseElement.value[i] == val) {
                                renderCondition = true;
                                break;
                            }
                        }
                    } else {
                        if (responseElement.value == val) {
                            renderCondition = true;
                            break;
                        }
                    }
                }

            }
        }
    }
    return renderCondition;

};

export const getQuestionsAffectedByChange = (responseKey,questions) => {
     let affectedQuestions = questions.filter((question) => {
        return question.conditions && -1 != question.conditions.findIndex((obj) => { return obj.questionKey == responseKey });
    });
    return affectedQuestions; 
}

export const priceFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
});
