export const mockPages = [
  {
    'key': 'gebaeudeTyp',
    'name': 'Gebäudetyp',
    'groupName': 'Gebäude',
    'label': 'Für welchen Gebäudetyp suchen Sie eine Heizung?*',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': true,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, für welchen Gebäudetyp Sie eine neue Heizung suchen, damit wir Ihren voraussichtlichen Energieverbrauches und eine mögliche KfW Förderung berücksichtigen können.',
      'text': 'Bitte geben Sie an, für welchen Gebäudetyp Sie eine neue Heizung suchen, damit wir Ihren voraussichtlichen Energieverbrauches und eine mögliche KfW Förderung berücksichtigen können.',
    },
    'answers': [
      {
        'label': 'Einfamilienhaus',
        'value': 1,
        'image': './page_images/einfamilienhaus.jpg'
      },
      {
        'label': 'Zweifamilienhaus',
        'value': 3,
        'image': './page_images/zweifamilienhaus.jpg'
      },
      {
        'label': 'Mehrfamilienhaus',
        'value': 2,
        'image': './page_images/mehrfamilienhaus.jpg'
      },
      {
        'label': 'Wohnung',
        'value': 5,
        'image': './page_images/wohnung.jpg'
      },
    ],
    'show': false,
    'b2cStep': 1,
  },
  {
    'key': 'gebaeudeVermietet',
    'name': 'Vermietung',
    'groupName': 'Gebäude',
    'label': 'In welcher Nähe steht Ihr Gebäude zu anderen?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Wenn Sie Eigentümer des Gebäudes sind, kommt für Sie ggf. eine KfW Förderung in Frage.',
      'text': 'Wenn Sie Eigentümer des Gebäudes sind, kommt für Sie ggf. eine KfW Förderung in Frage.',
    },
    'answers': [
      {
        'label': 'Freistehend',
        'value': 1,
        'image': './page_images/freistehend.jpg'
      },
      {
        'label': 'Reihenhaus',
        'value': 2,
        'image': './page_images/reihenhaus.jpg'
      },
    ],
    'show': false,
    'b2cStep': 2,
  },
  {
    'key': 'eigentumsVerhaeltnis',
    'name': 'Eigentums',
    'groupName': 'Gebäude',
    'label': 'Welches eigentumsVerhältnis liegt vor?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Wenn Sie Eigentümer des Gebäudes sind, kommt für Sie ggf. eine KfW Förderung in Frage.',
      'text': 'Wenn Sie Eigentümer des Gebäudes sind, kommt für Sie ggf. eine KfW Förderung in Frage.',
    },
    'answers': [
      {
        'label': 'Eigentümer',
        'value': 1,
        'image': './page_images/eigentuemer.jpg'
      },
      {
        'label': 'Mieter',
        'value': 2,
        'image': './page_images/mieter.jpg'
      },
    ],
    'show': false,
    'b2cStep': 3,
  },
  {
    'key': 'immobilienNutzung',
    'name': 'ImmobilienNutzung (Mehrfach Auswahl möglich)',
    'groupName': 'Gebäude',
    'label': 'Wie wird die Immobilie genutzt?',
    'type': 'iconMultiSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': '',
      'text': '',
    },
    'answers': [
      {
        'label': 'Private',
        'value': 1,
        'icon': 'keine',
      },
      {
        'label': 'Gewerblich',
        'value': 2,
        'icon': 'keine',
      },
    ],
    'show': false,
    'b2cStep': 4,
  },
  {
    'key': 'beheizteFlaeche_type1',
    'name': 'Wohnfläche',
    'groupName': 'Gebäude',
    'label': 'Wie groß ist die beheizende Fläche?*',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 30,
    'max': 500,
    'system': [],
    'required': true,
    'suffix': 'm²',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
      'text': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
    },
    'answers': [
      {
        'label': '30 - 150qm',
        'icon': 'LS1',
        'value': 90,
      },
      {
        'label': '150 - 260qm',
        'icon': 'LS2',
        'value': 200,
      },
      {
        'label': '260 - 380qm',
        'icon': 'LS3',
        'value': 320,
      },
      {
        'label': '380 - 500qm',
        'icon': 'LS4',
        'value': 420,
      },
    ],
    'conditions': [
      {
        'questionKey': 'gebaeudeTyp',
        'operator': '=',
        'value': 1,
        'disable': false,
      },
    ],
    'show': false,
    'b2cStep': 5,
  },
  {
    'key': 'beheizteFlaeche_type2',
    'name': 'Wohnfläche',
    'groupName': 'Gebäude',
    'label': 'Wie groß ist die beheizende Fläche?*',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 100,
    'max': 3000,
    'system': [],
    'required': true,
    'suffix': 'm²',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
      'text': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
    },
    'answers': [
      {
        'label': '100 - 850qm',
        'icon': 'LS1',
        'value': 450,
      },
      {
        'label': '850 - 1500qm',
        'icon': 'LS2',
        'value': 1200,
      },
      {
        'label': '1500 - 2200qm',
        'icon': 'LS3',
        'value': 1800,
      },
      {
        'label': '2200 - 3000qm',
        'icon': 'LS4',
        'value': 2500,
      },
    ],
    'conditions': [
      {
        'questionKey': 'gebaeudeTyp',
        'operator': '=',
        'value': 2,
        'disable': false,
      },
    ],
    'show': false,
    'b2cStep': 5,
  },
  {
    'key': 'beheizteFlaeche_type3',
    'name': 'Wohnfläche',
    'groupName': 'Gebäude',
    'label': 'Wie groß ist die beheizende Fläche?*',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 45,
    'max': 500,
    'system': [],
    'required': true,
    'suffix': 'm²',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
      'text': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
    },
    'answers': [
      {
        'label': '45 - 150qm',
        'icon': 'LS1',
        'value': 90,
      },
      {
        'label': '150 - 260qm',
        'icon': 'LS2',
        'value': 200,
      },
      {
        'label': '260 - 380qm',
        'icon': 'LS3',
        'value': 320,
      },
      {
        'label': '380 - 500qm',
        'icon': 'LS4',
        'value': 420,
      },
    ],
    'conditions': [
      {
        'questionKey': 'gebaeudeTyp',
        'operator': '=',
        'value': 3,
        'disable': false,
      },
    ],
    'show': false,
    'b2cStep': 5,
  },
  {
    'key': 'beheizteFlaeche_type5',
    'name': 'Wohnfläche',
    'groupName': 'Gebäude',
    'label': 'Wie groß ist die beheizende Fläche?*',
    'type': 'iconSelect',
    'inputType': 'number',
    'min': 45,
    'max': 500,
    'system': [],
    'required': true,
    'suffix': 'm²',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
      'text': 'Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden.',
    },
    'answers': [
      {
        'label': '45 - 125qm',
        'icon': 'LS1',
        'value': 80,
      },
      {
        'label': '125 - 200qm',
        'icon': 'LS2',
        'value': 150,
      },
      {
        'label': '200 - 280qm',
        'icon': 'LS3',
        'value': 240,
      },
      {
        'label': '280 - 350qm',
        'icon': 'LS4',
        'value': 320,
      },
    ],
    'conditions': [
      {
        'questionKey': 'gebaeudeTyp',
        'operator': '=',
        'value': 5,
        'disable': false,
      },
    ],
    'show': false,
    'b2cStep': 5,
  },
  {
    'key': 'bestehendeBrennstoffversorgung',
    'name': 'Brennstoffversorgung',
    'groupName': 'System aktuell',
    'label': 'Mit welcher Energieart heizen Sie aktuell?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können.',
      'text': 'Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können.',
    },
    'answers': [
      {
        'label': 'Gas',
        'value': 1,
        'image': './page_images/gas.jpg'
      },
      {
        'label': 'Öl',
        'value': 2,
        'image': './page_images/oil.jpg'
      },
      {
        'label': 'Strom',
        'value': 4,
        'image': './page_images/strom.jpg'
      },
      {
        'label': 'Sonstiges',
        'value': 3,
        'image': './page_images/weder-noch.jpg'
      },
    ],
    'show': false,
    'b2cStep': 6,
  },
  {
    'key': 'schornstein',
    'name': 'Gebäudetyp',
    'groupName': 'Gebäude',
    'label': 'Wo befindet sich die Heizungsanlage?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, wo sich die Heizungsanlage aktuell oder zukünftig befindet, damit wir die nötigen Materialien und Montagearbeiten planen können.',
      'text': 'Bitte geben Sie an, wo sich die Heizungsanlage aktuell oder zukünftig befindet, damit wir die nötigen Materialien und Montagearbeiten planen können.',
    },
    'answers': [
      {
        'label': 'unter dem Dach',
        'value': 2,
        'image': './page_images/dach.jpg'
      },
      {
        'label': 'in der Wohnung',
        'value': 3,
        'image': './page_images/in-der-wohnung.jpg'
      },
      {
        'label': 'im Keller',
        'value': 1,
        'image': './page_images/keller.jpg'
      },
      {
        'label': 'Sonstiges',
        'value': 4,
        'image': './page_images/weder-noch.jpg'
      },
    ],
    'show': false,
    'b2cStep': 7,
  },
  {
    'key': 'b2cHeizkoerperart',
    'name': 'Brennstoffversorgung (Mehrfach Auswahl möglich)',
    'groupName': 'System aktuell',
    'label': 'Welche Wärmeverteiler sind vorhanden?',
    'type': 'iconMultiSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können.',
      'text': 'Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können.',
    },
    'answers': [
      {
        'label': 'Heizkörper',
        'value': 1,
        'image': './page_images/heizkoerper.jpg'
      },
      {
        'label': 'Fußbodenheizung',
        'value': 2,
        'image': './page_images/fussbodenheizung.jpg'
      },
    ],
    'conditions': [
      {
        'questionKey': 'bestehendeBrennstoffversorgung',
        'operator': 'contains',
        'value': [
          1,
          2,
          3,
          4,
        ],
        'disable': false,
      },
    ],
    'show': false,
    'b2cStep': 8,
  },
  {
    'key': 'artWarmwasserbereitung',
    'name': 'Warmwasserbereitung',
    'groupName': 'System aktuell',
    'label': 'Wie erhitzen Sie ihr Wasser aktuell?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, über welches Gerät Ihr Wasser aktuell erwärmt wird, damit wir die nötigen Materialien und Montagearbeiten planen können.',
      'text': 'Bitte geben Sie an, über welches Gerät Ihr Wasser aktuell erwärmt wird, damit wir die nötigen Materialien und Montagearbeiten planen können.',
    },
    'answers': [
      {
        'label': 'Heizungssystem',
        'value': 1,
        'image': './page_images/heizungssystem.jpg'
      },
      {
        'label': 'Durchlauferhitzer',
        'value': 2,
        'image': './page_images/durchlauferhitzer.jpg'
      },
      {
        'label': 'Sonstiges',
        'value': 4,
        'image': './page_images/weder-noch.jpg'
      },
    ],
    'show': false,
    'b2cStep': 9,
  },
  {
    'key': 'zukuenftigeBrennstoffversorgung',
    'name': 'Zukünftige Versorgungstechnologie (Mehrfach Auswahl möglich)',
    'groupName': 'Gebäude',
    'label': 'Über welche Heiztechnologien wollen Sie sich informieren?',
    'type': 'iconMultiSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'information': {
      'title': 'Bitte geben Sie an, mit welcher Energieart Ihre Heizung Sie zukünftig mit Wärme versorgen soll, damit wir eine passende Heizung für Sie finden.',
      'text': 'Bitte geben Sie an, mit welcher Energieart Ihre Heizung Sie zukünftig mit Wärme versorgen soll, damit wir eine passende Heizung für Sie finden.',
    },
    'answers': [
      {
        'label': 'Gas',
        'value': 'gas',
        'image': './page_images/gas.jpg'
      },
      {
        'label': 'Öl',
        'value': 'oel',
        'image': './page_images/oil.jpg'
      },
    ],
    'show': false,
    'b2cStep': 10,
  },
  {
    'key': 'zusatzSolar',
    'name': 'zusatzSolar',
    'groupName': 'System künftig',
    'label': 'Ist Solar als zusätzliche Wärmequelle gewünscht?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'answers': [
      {
        'label': 'Solarunterstützung',
        'value': true,
        'image': './page_images/solarunterstuetzung.jpg'
      },
    ],
    'conditions': [
      {
        'questionKey': 'gebaeudeTyp',
        'operator': 'contains',
        'value': [
          1,
          3,
        ],
        'disable': false,
      },
    ],
    'show': false,
    'b2cStep': 11,
  },
  {
    'key': 'umbauPlan',
    'name': 'UmbauPlan',
    'groupName': 'System künftig',
    'label': 'Wann soll der Umbau erfolgen?',
    'type': 'iconSelect',
    'inputType': 'text',
    'min': 0,
    'max': 0,
    'system': [],
    'required': false,
    'suffix': '',
    'additionalTextInput': false,
    'answers': [
      {
        'label': 'Sofort',
        'value': 1,
        'icon': 'PL1',
      },
      {
        'label': 'Diesen Monat',
        'value': 2,
        'icon': 'PL2',
      },
      {
        'label': 'In 1-2 Monaten',
        'value': 3,
        'icon': 'PL3',
      },
      {
        'label': 'Weiß ich noch nicht',
        'value': 4,
        'icon': 'S',
      },
    ],
    'show': false,
    'b2cStep': 12,
  },
];
