import React from 'react';
import SVG from 'react-inlinesvg';
import Icons from "../../icons";

export const WelcomePage = ({onStartClick, isTabletOrMobile}) => {
    return (
        <div className="welcome text-center">
            <div className="top">
                <div className="headline">Heizungskonfigurator</div>
                <div className="title">In wenigen Schritten zu Ihrem persönlichen
                    Heizungsangebot.
                </div>
            </div>

            <div className="welcome-content">
                <div className="welcome-content-item">
                    <SVG src={Icons.welcome_konfigurieren} width="80" height="80"/>
                    <div className="welcome-content-item-content">
                        <div className="welcome-headline">1. Heizung konfigurieren</div>
                        <div className="welcome-text">Nach Eingabe Ihrer Daten erstellen
                            wir Angebote passend zu Ihrer
                            Wunschkonfiguration.
                        </div>
                    </div>
                </div>
                {!isTabletOrMobile && <div className="separator"/>}
                <div className="welcome-content-item">
                    <SVG src={Icons.welcome_angebot} width="80" height="80"/>
                    <div className="welcome-content-item-content">
                        <div className="welcome-headline">2. Angebot auswählen</div>
                        <div className="welcome-text">Vergleichen Sie die Ergebnisse und
                            wählen das für Sie passende Angebot aus.
                        </div>
                    </div>
                </div>
                {!isTabletOrMobile && <div className="separator"/>}
                <div className="welcome-content-item">
                    <SVG src={Icons.welcome_termin} width="80" height="80"/>
                    <div className="welcome-content-item-content">
                        <div className="welcome-headline">3. Termin vereinbaren</div>
                        <div className="welcome-text">Um einen passenden Termin zu finden,
                            setzen wir uns mit Ihnen in Verbindung.
                        </div>
                    </div>
                </div>
                {!isTabletOrMobile && <div className="separator"/>}
                <div className="welcome-content-item">
                    <SVG src={Icons.welcome_montage} width="80" height="80"/>
                    <div className="welcome-content-item-content">
                        <div className="welcome-headline">4. Heizung montieren</div>
                        <div className="welcome-text">Nach abschliessender Prüfung und
                            Auftragserteilung erfolgt die Montage Ihrer
                            Heizung.
                        </div>
                    </div>
                </div>
            </div>

            <button className="buttonHighlight" onClick={onStartClick}>Jetzt
                konfigurieren
            </button>

        </div>
    );
};
