const defaultConfig = {
  privacyLink: 'https://www.elements-show.de/datenschutz',
  agbLink: 'https://www.elements-show.de/impressum',
  partnerId: 'd20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  questionsUrl: 'https://www.elements-a.de/elements_api/prod/questions/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  contactUrl: 'https://www.elements-a.de/elements_api/prod/contacts/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  productUrl: 'https://www.elements-a.de/elements_api/prod/products/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  afterFormSuccessUrl: '/termin-vereinbaren-heizungsplaner',
  watchListUrl: 'https://universal.dasbad3.de/universalbackend/public/api/heaterconfig/sendMail',
  isDarkMode: 0,
  colors: {
    'highlight1': '#BF8C4A',
    'highlight2': '#BDBDBD',
    'background': '#FFFFFF',
    'accent_1': '#E5E5E5',
    'accent_2': '#7F7F7F',
    'accent_3': '#EBEBEB',
    'font': '#1a1a1a',
  },
};

const defaultConfigDark = {
  privacyLink: '',
  agbLink: '',
  partnerId: 'd20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  questionsUrl: 'https://www.elements-a.de/elements_api/prod/questions/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  contactUrl: 'https://www.elements-a.de/elements_api/prod/contacts/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  productUrl: 'https://www.elements-a.de/elements_api/prod/products/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  afterFormSuccessUrl: 'https://google.de/',
  watchListUrl: 'content',
  watchList: {
    "results": [{"key": "gebaeudeTyp", "value": "3"}, {
      "key": "gebaeudeVermietet",
      "value": "2"
    }, {"key": "beheizteFlaeche_type3", "value": "320"}], "items": [683, 667]
  },
  isDarkMode: 1,
  colors: {
    'highlight1': '#FFFFFF',
    'highlight2': '#BDBDBD',
    'background': '#000000',
    'accent_1': '#303030',
    'accent_2': '#656565',
    'accent_3': '#EBEBEB',
    'font': '#FFFFFF',
  },
};

const defaultConfigTest = {
  privacyLink: '',
  agbLink: '',
  partnerId: 'd20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  questionsUrl: 'https://www.elements-a.de/elements_api/dev/questions/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  contactUrl: 'https://www.elements-a.de/elements_api/dev/contacts/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  productUrl: 'https://www.elements-a.de/elements_api/dev/products/?partner_id=d20e9ce86b55e52d3c4d98fdec5798007f8b238f',
  afterFormSuccessUrl: 'https://www.google.de',
  isDarkMode: 1,
  colors: {
    'highlight1': '#ff0000',
    'highlight2': '#00ff55',
    'background': '#00cc44',
    'accent_1': '#003311',
    'accent_2': '#006622',
    'accent_3': '#009933',
    'font': '#0000ff',
  },
};

export {defaultConfig, defaultConfigDark, defaultConfigTest};
