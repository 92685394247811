import React, {useContext, useEffect, useState} from 'react';
import {Result} from './Result';
import {GlobalContext} from '../../context';
import {Carousel} from 'react-responsive-carousel';

export const ResultsPage = ({goToContactForm, responses, isTabletOrMobile, afterFormSuccessUrl}) => {

    const [selectValue, setSelectValue] = useState(
        {value: 'standard', label: 'Standard'});

    const [wishlistFormMail, setWishlistFormMail] = useState('');
    const [wishlistFormNote, setWishlistFormNote] = useState('');
    const [wishlistSent, setWishlistSent] = useState(false);
    const [loading, setLoading] = useState(true);
    const {productsArray, fetchProducts, updateWishlist, sendWatchlist, wishlistArray, responseArray, showWishlist} = useContext(
        GlobalContext);
    const [selectMenu, setSelectMenu] = useState(showWishlist ? 'wishlist' : 'results');

    const options = [
        {value: 'standard', label: 'Standard'},
        {value: 'decrementing', label: 'Absteigend'},
        {value: 'incrementing', label: 'Aufsteigend'},
    ];

    const handleChange = selectedOption => {
        console.log(selectedOption);
        setSelectValue(selectedOption);
    };

    const goToForm = (productinfo) => {
        goToContactForm(productinfo);
    };

    const handleWishlistFormSubmit = async () => {
        const wishlistForm = {
            email: wishlistFormMail,
            note: wishlistFormNote,
        };
        await sendWatchlist(responseArray, wishlistForm, wishlistArray);
        setWishlistSent(true);
        // clear form
        setWishlistFormMail('');
        setWishlistFormNote('');

    };

    const canSubmit = () => {
        return wishlistFormMail !== '' && wishlistFormNote !== '';
    };

    const sortArray = (input) => {
        // sorting
        let sortedArray = [...input];
        if (selectValue.value == "incrementing") {
            sortedArray.sort((a, b) => {
                return a.pricesFrom - b.pricesFrom
            })
        }
        if (selectValue.value == "decrementing") {
            sortedArray.sort((a, b) => {
                return b.pricesFrom - a.pricesFrom
            })
        }
        return sortedArray;
    }

    useEffect(() => {
        setLoading(true);
        fetchProducts(responses);
    }, [responses]);

    // // fetchProducts to display
    useEffect(() => {
        if (productsArray) {
            setTimeout(function () {
                setLoading(false);
                let height = document.getElementById("maincontainer").clientHeight - 300;
                console.log("event feuern", document.getElementById("maincontainer").clientHeight, document.getElementById("maincontainer").scrollHeight);
                window.parent.postMessage({type: "iframe", message: "resize", height: height}, '*');
            }, 250);

            //window.parent.postMessage({ type: "iframe", message: "resize", height: document.body.scrollHeight }, '*');
        }

    }, [productsArray])

    const renderResults = () => {
        // sorting
        let sortedArray = sortArray(productsArray);

        if (!isTabletOrMobile) {
            return (
                sortedArray.map((product, i) => {
                    return (
                        <Result key={i} goToContactForm={goToContactForm}
                                product={product} i={i}
                                checked={wishlistArray.findIndex((obj) => {
                                    return obj.id == product.id
                                }) !==
                                -1} isWishList={false}
                                updateWishlist={(
                                    product, checked) => updateWishlist(product,
                                    checked)}
                                afterFormSuccessUrl={afterFormSuccessUrl}/>
                    );
                })
            )
        } else {
            return (<Carousel style={{'height': '100%'}}>
                {sortedArray.map((product, i) => {
                    return (
                        <Result key={i} goToContactForm={goToContactForm}
                                product={product} i={i}
                                checked={wishlistArray.findIndex((obj) => {
                                    return obj.id == product.id
                                }) !==
                                -1} isWishList={false}
                                updateWishlist={(
                                    product, checked) => updateWishlist(product,
                                    checked)}
                                afterFormSuccessUrl={afterFormSuccessUrl}/>
                    );
                })}
            </Carousel>)
        }
    }

    if (!productsArray || loading) {
        return (<div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>)
    }

    function renderEmptyResults() {

        if (productsArray && productsArray.length <= 0 && !loading) {
            return (<div className="">
                <center><div class={"title"}>Leider wurden für deine Auswahl keine möglichen Ergebnisse gefunden.</div>
                <br/>
                <div class={"welcome-text"}>Versuche oben "<b>Ihre Angaben bearbeiten</b>" und wähle z.B. statt "Pellets" eine andere Heizungstechnik.</div>
                <br/><br/><br/></center>
            </div>)
        }

    }


    return (
        <div className="results col-md-12">
            <div className="top">
                <div className="title">
                    <span
                        className={selectMenu === 'results' ?
                            'title-menu-item selected' :
                            'title-menu-item'}
                        onClick={() => setSelectMenu(
                            'results')}>Ergebnisse [{productsArray.length}]</span>
                    <span
                        className={selectMenu === 'wishlist' ?
                            'title-menu-item selected' :
                            'title-menu-item'}
                        onClick={() => setSelectMenu(
                            'wishlist')}>Favoriten [{wishlistArray.length}]</span>
                </div>

                <div className="select-container">
                    <span className="control" data-toggle="dropdown">
                        {selectValue.label} <i className="fa fa-chevron-down"/>
                    </span>
                    <ul className="dropdown-menu w-25">
                        {options.map((option, index) => {
                            return (<a key={index} className="dropdown-item px-2" href="#"
                                       onClick={() => handleChange(option)}>
                                {selectValue.label == option.label ? <b>{option.label}</b> : option.label}
                            </a>);
                        })}
                    </ul>

                </div>


            </div>

            <div className="results-content col-12 container-fluid">

                {renderEmptyResults()}

                {selectMenu === 'results' ?
                    <div className="row">
                        {renderResults()}
                    </div>

                    :

                    <>
                        <div className="row">
                            {wishlistArray.length > 0 &&
                            sortArray(wishlistArray).map((wishlistProduct, i) => {
                                return (
                                    <Result key={i} goToContactForm={goToContactForm}
                                            product={wishlistProduct} i={i}
                                            isWishList={true}
                                            checked={wishlistArray.indexOf(
                                                wishlistProduct) !== -1}
                                            updateWishlist={(
                                                product, checked) => updateWishlist(product,
                                                checked)}
                                            afterFormSuccessUrl={afterFormSuccessUrl}/>
                                );
                            })}

                            {wishlistArray.length === 0 &&
                            <div className="w-100 text-center">Ihre Merkliste ist momentan
                                leer. Bitte fügen Sie Artikel aus der
                                Ergebnisliste
                                hinzu.</div>
                            }
                        </div>
                        {wishlistArray.length > 0 &&

                        <>
                            <div className="welcome">
                                <div className="title">Merkliste versenden</div>
                                <div className="wishlist-form-headline">Senden Sie Ihre
                                    Auswahl an sich oder andere.
                                </div>
                            </div>
                            {/*success message */}
                            {wishlistSent && (
                                <div className="col-12">
                                    <div className="w-100 text-center py-3">
                                        Vielen Dank für Ihre Anfrage, Ihr Merkzettel wurde an die angegebene E-Mail verschickt!
                                    </div>
                                </div>
                            )}
                            <div className="row col-12">
                                {/*left*/}
                                <div className="col-md-6 col-12">
                                    <div className="row">
                                        <legend>E-Mailadresse*</legend>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="E-Mailadresse eingeben"
                                            value={wishlistFormMail}
                                            name="email"
                                            required
                                            onChange={(e) => setWishlistFormMail(
                                                e.target.value)}
                                        />
                                    </div>

                                    <div className="row">
                                        <button disabled={!canSubmit()} type="submit"
                                                className="col-12 col-md-12 col-sm-12"
                                                onClick={handleWishlistFormSubmit}>
                                            Merkliste senden
                                        </button>
                                    </div>
                                </div>

                                {/*right*/}
                                <div className="col-md-6 col-12">

                                    <legend>Anmerkungen*</legend>
                                    <textarea className="form-control"
                                              id="exampleFormControlTextarea1" rows="4"
                                              onChange={(e) => setWishlistFormNote(
                                                  e.target.value)}
                                              name="note"
                                              placeholder="Geben Sie hier ihre Anmerkungen ein"
                                              value={wishlistFormNote}/>
                                </div>
                            </div>

                        </>
                        }

                    </>
                }


            </div>


        </div>
    );
};
