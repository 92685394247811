import React, {useContext} from 'react';
import {Question} from '../Question';
import SVG from 'react-inlinesvg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {GlobalContext} from '../../context';
import Icons from "../../icons";

export const QuestionsPage = ({questions, title, infoBoxText, infoBoxLabel, selectAnswer, selectedQuestionFromPage, currentPageIndex, virtualPaginationSize, handleGoBack, isTabletOrMobile, isMultiSelect}) => {
    const {isDarkMode} = useContext(GlobalContext);
    const renderInfoBox = (text) => {
        return (
            <div className="infoBoxContainer">
                <div className="infoBox inner-element bubble-element clickable-element"
                     data-toggle="tooltip"
                     data-placement="bottom"
                     data-template={isDarkMode ?
                         '<div class="tooltip" role="tooltip"><div class="arrow custom-tooltip-arrow"></div><div class="tooltip-inner custom-tooltip-dark"></div></div>' :
                         '<div class="tooltip" role="tooltip"><div class="arrow custom-tooltip-arrow"></div><div class="tooltip-inner custom-tooltip"></div></div>'}
                     data-html="true"
                     title={text}
                >
                    <SVG src={isDarkMode ? Icons.info_dark : Icons.info_light}
                         width="33" height="33"/>
                    {infoBoxLabel}
                </div>
            </div>
        );
    };

    const renderQuestions = () => {
        if (!questions || !questions.length) {
            return;
        }
        return questions.map((question, index) => {
            return (
                // <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                <div className={isTabletOrMobile ? 'col-lg-3 col-md-4 col-6' : 'col-lg-3 col-md-3 col-sm-3'} key={index}>
                    <Question
                        text={question.label}
                        icon={question.icon}
                        image={question.image}
                        selectAnswer={(answer) => selectAnswer(answer, question.label)}
                        id={question.value.toString()}
                        isSelected={selectedQuestionFromPage ? selectedQuestionFromPage.indexOf(question.value.toString()) !== -1 : false}
                        isTabletOrMobile={isTabletOrMobile}
                    />
                </div>
            );
        });
    };

    const renderQuestionsCarousel = () => {
        if (!questions || !questions.length) {
            return;
        }

        return (
            <Carousel style={{'height': '100%'}}>
                {questions.map((question, index) => {
                    return (
                        <div className="col-lg-12" key={index}>
                            <Question
                                text={question.label}
                                icon={question.icon}
                                image={question.image}
                                selectAnswer={(answer) => selectAnswer(answer, question.label)}
                                id={question.value.toString()}
                                isSelected={selectedQuestionFromPage ? selectedQuestionFromPage.indexOf(question.value.toString()) !== -1 : false}
                                isTabletOrMobile={isTabletOrMobile}
                            />
                        </div>
                    );
                })}
            </Carousel>
        );
    };

    return (
        <div className="page">
            <div className="pageHeader">
                <div className="title">{title}</div>
                {infoBoxText && renderInfoBox(infoBoxText)}
            </div>
            <div className="container">
                <div className="row flexCenter">{renderQuestions()}</div>
            </div>
            {isMultiSelect && <div className="multiple-description">Mehrfachauswahl möglich</div>}
        </div>
    );
};
