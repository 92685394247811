import {mockPages} from '../defaultData/defaultPages';

export class ThermoboxService {

  productUrl;
  questionsUrl;
  contactUrl;

  constructor(config) {
    if (config) {
      this.productUrl = config.productUrl;
      this.contactUrl = config.contactUrl;
      this.questionsUrl = config.questionsUrl;
      this.watchListUrl = config.watchListUrl || "noDefaultURL";
      this.configId = config.id || "5e8ee4f0085bc157315786bf";
      this.partnerId = config.partnerId;
    }
  }

  fetchProducts = async (responses) => {
    const result = await fetch(this.productUrl, {
      method: 'post',
    /*  headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },*/
      body: JSON.stringify(responses),
    });

    const remoteConfiguration = await result.json();
    let {products} = remoteConfiguration;
    console.log(remoteConfiguration);
    return products;
  };

  sendForm = async (completeResponse) => {
    const promises = [];
    let headers = new Headers();
    const body = {...completeResponse, partnerId: this.partnerId};
   // headers.set('Content-Type', 'application/json');
    //headers.set('Accept', 'application/json, text/plain, */*');
    const result = fetch(this.contactUrl, {
      method: 'post',
   //   headers: headers,
      body: JSON.stringify(body),
    });
    promises.push(result)
    try {
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  };

  sendWatchlist = async (responseArray, whishlistForm, whishlistItems) => {
    const promises = [];
    let headers = new Headers();
    headers.set('Content-Type', 'application/json');
    //headers.set('Accept', 'application/json, text/plain, */*');
    let reducedWishlist = whishlistItems.map((item, i) => {
      return item.id;
    })
    // wishlist object
    let wishlist = {
      results: responseArray,
      items: reducedWishlist,
    }

    let content = {
      config_id: this.configId,
      contact: {
        email: whishlistForm.email,
        note: whishlistForm.note
      },
      wishlist: wishlist
    }

    const result = fetch(this.watchListUrl, {
      method: 'POST',
   //   mode: 'no-cors',
      headers: headers,
      body: JSON.stringify(content),
    });
    promises.push(result);
    try {
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  };

  fetchPages = async () => {
    console.log('questionsURL %s', this.questionsUrl);
    if (this.questionsUrl) {
      try {
        const result = await fetch(this.questionsUrl, {
          method: 'get',
         // headers: {'Content-Type': 'application/json'},
        });
        return await result.json();

      } catch (e) {
        console.log('cathing error', e);
        // errror, return mock object
        return mockPages;
      }

    } else {
      return mockPages;
    }
  };
}
