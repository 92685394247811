// mock 

import { areConditionsTrue, getUpdatedResponseArrayAddOrChangeValue, getUpdatedResponseArrayRemoveValue } from "./utils";

const mock = [
    {
        "key": "gebaeudeTyp",
        "value": 3
    },
    {
        "key": "energieTraeger",
        "value": 4
    },
    {
        "key": "gebaeudeVermietet",
        "value": 2
    },
    {
        "key": "eigentumsVerhaeltnis",
        "value": 2
    },
    {
        "key": "immobilienNutzung",
        "value": [
            2,
            1
        ]
    },
    {
        "key": "beheizteFlaeche_type3",
        "value": 320
    },
    {
        "key": "bestehendeBrennstoffversorgung_pellet",
        "value": 2
    },
    {
        "key": "pelletLagerung_oel",
        "value": 2
    },
    {
        "key": "b2cHeizkoerperart_pellet",
        "value": [
            2
        ]
    },
    {
        "key": "artWarmwasserbereitungPellets",
        "value": 3
    },
    {
        "key": "umbauPlan",
        "value": 3
    }
];

const questions = [
    {
        "key": "gebaeudeTyp",
        "name": "Gebäudetyp",
        "groupName": "Gebäude",
        "label": "Für welchen Gebäudetyp suchen Sie eine Heizung?*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "BTM",
        "system": [],
        "required": true,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, für welchen Gebäudetyp Sie eine neue Heizung suchen, damit wir Ihren voraussichtlichen Energieverbrauches berücksichtigen können."
        },
        "answers": [
            {
                "label": "Einfamilienhaus",
                "value": 1,
                "icon": "BT1"
            },
            {
                "label": "Zweifamilienhaus",
                "value": 3,
                "icon": "BT2"
            },
            {
                "label": "Mehrfamilienhaus",
                "value": 2,
                "icon": "BT3"
            },
            {
                "label": "Wohnung",
                "value": 5,
                "icon": "BT4"
            }
        ],
        "show": false,
        "b2cStep": 1
    },
    {
        "key": "energieTraeger",
        "name": "Technologieauswahl",
        "groupName": "System künftig",
        "label": "Auswahl der geplanten Technologie:*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "system": [],
        "required": true,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Hier können Sie zwischen verschiedenen Technologien der Haustechnik wählen."
        },
        "answers": [
            {
                "label": "Heizungstechnik",
                "value": 1,
                "icon": "!"
            },
            {
                "label": "Pelletheizung",
                "value": 4,
                "icon": "P"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            }
        ],
        "show": true,
        "b2cStep": 1,
        "value": 1
    },
    {
        "key": "gebaeudeVermietet",
        "name": "Vermietung",
        "groupName": "Gebäude",
        "label": "Um welchen Haustyp handelt es sich?",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "RTM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Wenn die Außenwände des Gebäudes an keine weiteren Nebengebäude grenzen, wirkt sich dieses auf die Berechnung des Energieverbrauchs aus."
        },
        "answers": [
            {
                "label": "Freistehend",
                "value": 1,
                "icon": "RT1"
            },
            {
                "label": "Reihenhaus",
                "value": 2,
                "icon": "RT2"
            }
        ],
        "show": false,
        "b2cStep": 2
    },
    {
        "key": "eigentumsVerhaeltnis",
        "name": "Eigentums",
        "groupName": "Gebäude",
        "label": "Welches Eigentumsverhältnis liegt vor?",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "ORM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Für Eigentümer kommen u.U. Fördermöglichkeiten in Frage."
        },
        "answers": [
            {
                "label": "Eigentümer",
                "value": 1,
                "icon": "OR1"
            },
            {
                "label": "Mieter",
                "value": 2,
                "icon": "OR2"
            }
        ],
        "show": false,
        "b2cStep": 3
    },
    {
        "key": "immobilienNutzung",
        "name": "ImmobilienNutzung (Mehrfach Auswahl möglich)",
        "groupName": "Gebäude",
        "label": "Wie wird die Immobilie genutzt?",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "REU",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": ""
        },
        "answers": [
            {
                "label": "Privat",
                "value": 1,
                "icon": "REU1"
            },
            {
                "label": "Gewerblich",
                "value": 2,
                "icon": "REU2"
            }
        ],
        "show": false,
        "b2cStep": 4
    },
    {
        "key": "beheizteFlaeche_type1",
        "name": "Wohnfläche",
        "groupName": "Gebäude",
        "label": "Wie groß ist die beheizende Fläche?*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 30,
        "max": 500,
        "icon": "LSM",
        "system": [],
        "required": true,
        "suffix": "m²",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden."
        },
        "answers": [
            {
                "label": "30 - 150qm",
                "icon": "LS1",
                "value": 90
            },
            {
                "label": "150 - 260qm",
                "icon": "LS2",
                "value": 200
            },
            {
                "label": "260 - 380qm",
                "icon": "LS3",
                "value": 320
            },
            {
                "label": "380 - 500qm",
                "icon": "LS4",
                "value": 420
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 1,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 5
    },
    {
        "key": "beheizteFlaeche_type2",
        "name": "Wohnfläche",
        "groupName": "Gebäude",
        "label": "Wie groß ist die beheizende Fläche?*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 100,
        "max": 3000,
        "icon": "LSM",
        "system": [],
        "required": true,
        "suffix": "m²",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden."
        },
        "answers": [
            {
                "label": "100 - 850qm",
                "icon": "LS1",
                "value": 450
            },
            {
                "label": "850 - 1500qm",
                "icon": "LS2",
                "value": 1200
            },
            {
                "label": "1500 - 2200qm",
                "icon": "LS3",
                "value": 1800
            },
            {
                "label": "2200 - 3000qm",
                "icon": "LS4",
                "value": 2500
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 2,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 5
    },
    {
        "key": "beheizteFlaeche_type3",
        "name": "Wohnfläche",
        "groupName": "Gebäude",
        "label": "Wie groß ist die beheizende Fläche?*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 45,
        "max": 500,
        "icon": "LSM",
        "system": [],
        "required": true,
        "suffix": "m²",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden."
        },
        "answers": [
            {
                "label": "45 - 150qm",
                "icon": "LS1",
                "value": 90
            },
            {
                "label": "150 - 260qm",
                "icon": "LS2",
                "value": 200
            },
            {
                "label": "260 - 380qm",
                "icon": "LS3",
                "value": 320
            },
            {
                "label": "380 - 500qm",
                "icon": "LS4",
                "value": 420
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 3,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 5
    },
    {
        "key": "beheizteFlaeche_type5",
        "name": "Wohnfläche",
        "groupName": "Gebäude",
        "label": "Wie groß ist die beheizende Fläche?*",
        "type": "iconSelect",
        "inputType": "number",
        "min": 45,
        "max": 500,
        "icon": "LSM",
        "system": [],
        "required": true,
        "suffix": "m²",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, wie groß die Gesamtfläche der Räume ist, die beheizt werden, damit wir auf Basis Ihres voraussichtlichen Energieverbrauches Heizungsanlagen für Sie finden."
        },
        "answers": [
            {
                "label": "45 - 125qm",
                "icon": "LS1",
                "value": 80
            },
            {
                "label": "125 - 200qm",
                "icon": "LS2",
                "value": 150
            },
            {
                "label": "200 - 280qm",
                "icon": "LS3",
                "value": 240
            },
            {
                "label": "280 - 350qm",
                "icon": "LS4",
                "value": 320
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 5,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 5
    },
    {
        "key": "bestehendeBrennstoffversorgung",
        "name": "Brennstoffversorgung",
        "groupName": "System aktuell",
        "label": "Mit welcher Energieart heizen Sie aktuell? Ein/Zweifamilienhaus NUR Heizungstechnik",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "EFS",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Gas",
                "value": 1,
                "icon": "G"
            },
            {
                "label": "Öl",
                "value": 2,
                "icon": "O"
            },
            {
                "label": "Strom",
                "value": 4,
                "icon": "E"
            },
            {
                "label": "Sonstiges",
                "value": 3,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            },
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 1,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 6
    },
    {
        "key": "bestehendeBrennstoffversorgung_mehrfamilienhaus",
        "name": "Brennstoffversorgung",
        "groupName": "System aktuell",
        "label": "Mit welcher Energieart heizen Sie aktuell? Mehrfamilienhaus NUR Heizungstechnik",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "EFS",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Gas",
                "value": 1,
                "icon": "G"
            },
            {
                "label": "Öl",
                "value": 2,
                "icon": "O"
            },
            {
                "label": "Strom",
                "value": 4,
                "icon": "E"
            },
            {
                "label": "Sonstiges",
                "value": 3,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 2,
                "disable": false
            },
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 1,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 6
    },
    {
        "key": "bestehendeBrennstoffversorgung_pellet",
        "name": "Brennstoffversorgung",
        "groupName": "System aktuell",
        "label": "Mit welcher Energieart heizen Sie aktuell?* PELLET",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "EFS",
        "system": [],
        "required": true,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Gas",
                "value": 1,
                "icon": "G"
            },
            {
                "label": "Öl",
                "value": 2,
                "icon": "O"
            },
            {
                "label": "Sonstiges",
                "value": 3,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 4,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 6
    },
    {
        "key": "bestehendeBrennstoffversorgung_wohnung",
        "name": "Brennstoffversorgung",
        "groupName": "System aktuell",
        "label": "Mit welcher Energieart heizen Sie aktuell? WOHNUNG",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "EFS",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, mit welcher Energieart Sie aktuell heizen, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Gas",
                "value": 1,
                "icon": "G"
            },
            {
                "label": "Strom",
                "value": 4,
                "icon": "E"
            },
            {
                "label": "Sonstiges",
                "value": 3,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 5,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 6
    },
    {
        "key": "pelletLagerung_gas",
        "name": "Pellet Lagerung",
        "groupName": "System künftig",
        "label": "Wie sollen die Pellets gelagert werden?*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "system": [],
        "required": true,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Lagerraum muss erstellt werden"
        },
        "answers": [
            {
                "label": "Sacksilo",
                "value": 1,
                "icon": ""
            },
            {
                "label": "Lagerraum",
                "value": 2,
                "icon": ""
            }
        ],
        "conditions": [
            {
                "questionKey": "bestehendeBrennstoffversorgung_pellet",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 0
    },
    {
        "key": "pelletLagerung_oel",
        "name": "Pellet Lagerung",
        "groupName": "System künftig",
        "label": "Wie sollen die Pellets gelagert werden?*",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "system": [],
        "required": true,
        "suffix": "",
        "additionalTextInput": false,
        "answers": [
            {
                "label": "Sacksilo",
                "value": 1,
                "icon": ""
            },
            {
                "label": "Nutzung alter Öl-Lagerraum",
                "value": 2,
                "icon": ""
            }
        ],
        "conditions": [
            {
                "questionKey": "bestehendeBrennstoffversorgung_pellet",
                "operator": "=",
                "value": 2,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 0
    },
    {
        "key": "schornstein",
        "name": "Aufstellort",
        "groupName": "Gebäude",
        "label": "Wo befindet sich die Heizungsanlage? NUR Heizungstechnik",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "CHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, wo sich die Heizungsanlage aktuell oder zukünftig befindet, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "unter dem Dach",
                "value": 2,
                "icon": "CH1"
            },
            {
                "label": "in der Wohnung",
                "value": 3,
                "icon": "CH2"
            },
            {
                "label": "im Keller",
                "value": 1,
                "icon": "CH3"
            },
            {
                "label": "Sonstiges",
                "value": 4,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    2,
                    3
                ],
                "disable": false
            },
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 1,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 7
    },
    {
        "key": "b2cHeizkoerperart",
        "name": "Vorhandene Wärmeverteiler (Mehrfach Auswahl möglich)",
        "groupName": "System aktuell",
        "label": "Nutzen Sie Heizkörper und/oder eine Fußbodenheizung?  Ein/Zweifamilienhaus",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "TOHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, ob Sie mit Heizkörpern und/oder Fußbodenheizung heizen. Dadurch können wir die Wärmeverteiler berücksichtigen."
        },
        "answers": [
            {
                "label": "Heizkörper",
                "value": 1,
                "icon": "TOH1"
            },
            {
                "label": "Fußbodenheizung",
                "value": 2,
                "icon": "TOH2"
            }
        ],
        "conditions": [
            {
                "questionKey": "bestehendeBrennstoffversorgung",
                "operator": "contains",
                "value": [
                    1,
                    2,
                    3,
                    4
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 8
    },
    {
        "key": "b2cHeizkoerperart_mehrfamilienhaus",
        "name": "Vorhandene Wärmeverteiler (Mehrfach Auswahl möglich)",
        "groupName": "System aktuell",
        "label": "Nutzen Sie Heizkörper und/oder eine Fußbodenheizung? Mehrfamilienhaus",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "TOHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, ob Sie mit Heizkörpern und/oder Fußbodenheizung heizen. Dadurch können wir die Wärmeverteiler berücksichtigen."
        },
        "answers": [
            {
                "label": "Heizkörper",
                "value": 1,
                "icon": "TOH1"
            },
            {
                "label": "Fußbodenheizung",
                "value": 2,
                "icon": "TOH2"
            }
        ],
        "conditions": [
            {
                "questionKey": "bestehendeBrennstoffversorgung_mehrfamilienhaus",
                "operator": "contains",
                "value": [
                    1,
                    2,
                    3,
                    4
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 8
    },
    {
        "key": "b2cHeizkoerperart_wohnung",
        "name": "Vorhandene Wärmeverteiler (Mehrfach Auswahl möglich)",
        "groupName": "System aktuell",
        "label": "Nutzen Sie Heizkörper und/oder eine Fußbodenheizung? Wohnung",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "TOHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, ob Sie mit Heizkörpern und/oder Fußbodenheizung heizen. Dadurch können wir die Wärmeverteiler berücksichtigen."
        },
        "answers": [
            {
                "label": "Heizkörper",
                "value": 1,
                "icon": "TOH1"
            },
            {
                "label": "Fußbodenheizung",
                "value": 2,
                "icon": "TOH2"
            }
        ],
        "conditions": [
            {
                "questionKey": "bestehendeBrennstoffversorgung_wohnung",
                "operator": "contains",
                "value": [
                    1,
                    3,
                    4
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 8
    },
    {
        "key": "b2cHeizkoerperart_pellet",
        "name": "Vorhandene Wärmeverteiler (Mehrfach Auswahl möglich)",
        "groupName": "System aktuell",
        "label": "Nutzen Sie Heizkörper und/oder eine Fußbodenheizung? Pellet",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "TOHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, ob Sie mit Heizkörpern und/oder Fußbodenheizung heizen. Dadurch können wir die Wärmeverteiler berücksichtigen."
        },
        "answers": [
            {
                "label": "Heizkörper",
                "value": 1,
                "icon": "TOH1"
            },
            {
                "label": "Fußbodenheizung",
                "value": 2,
                "icon": "TOH2"
            }
        ],
        "conditions": [
            {
                "questionKey": "bestehendeBrennstoffversorgung_pellet",
                "operator": "contains",
                "value": [
                    1,
                    2,
                    3
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 8
    },
    {
        "key": "artWarmwasserbereitung",
        "name": "Warmwasserbereitung",
        "groupName": "System aktuell",
        "label": "Wie erhitzen Sie ihr Wasser aktuell? Ein/Zweifamilienhaus NUR Heizungstechnik",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "WHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, über welches Gerät Ihr Wasser aktuell erwärmt wird, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Heizungssystem",
                "value": 1,
                "icon": "WH1"
            },
            {
                "label": "Durchlauferhitzer",
                "value": 2,
                "icon": "WH2"
            },
            {
                "label": "Sonstiges",
                "value": 4,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            },
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 1,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 9
    },
    {
        "key": "artWarmwasserbereitung_mehrfamilienhausWohnung",
        "name": "Warmwasserbereitung",
        "groupName": "System aktuell",
        "label": "Wie erhitzen Sie ihr Wasser aktuell? Mehrfamilienhaus Wohnung",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "WHM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, über welches Gerät Ihr Wasser aktuell erwärmt wird, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Heizungssystem",
                "value": 1,
                "icon": "WH1"
            },
            {
                "label": "Durchlauferhitzer",
                "value": 2,
                "icon": "WH2"
            },
            {
                "label": "Sonstiges",
                "value": 4,
                "icon": "S"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    2,
                    5
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 9
    },
    {
        "key": "artWarmwasserbereitungPellets",
        "name": "Warmwasserbereitung",
        "groupName": "System aktuell",
        "label": "Art der Warmwasserbereitung?* Ein/Zweifamilienhaus NUR Pellet",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "WHM",
        "system": [],
        "required": true,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, über welches Gerät Ihr Wasser aktuell erwärmt wird, damit wir die nötigen Materialien und Montagearbeiten planen können."
        },
        "answers": [
            {
                "label": "Speicher angeschlossen an Pufferspeicher",
                "value": 1,
                "icon": ""
            },
            {
                "label": "Hygiene-Pufferspeicher",
                "value": 2,
                "icon": ""
            },
            {
                "label": "Modul-Pufferspeicher mit Frischwasserstation",
                "value": 3,
                "icon": ""
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            },
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 4,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 9
    },
    {
        "key": "zukuenftigeBrennstoffversorgung",
        "name": "Gewünschte Heizart (Mehrfach Auswahl möglich)",
        "groupName": "Gebäude",
        "label": "Über welche Heiztechnologien wollen Sie sich informieren? Ein/Zweifamilienhaus NUR Heizungstechnik",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "DTH",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, mit welcher Energieart Ihre Heizung Sie zukünftig mit Wärme versorgen soll, damit wir eine passende Heizung für Sie finden."
        },
        "answers": [
            {
                "label": "Gas",
                "value": "gas",
                "icon": "G"
            },
            {
                "label": "Öl",
                "value": "oel",
                "icon": "O"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            },
            {
                "questionKey": "energieTraeger",
                "operator": "=",
                "value": 1,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 10
    },
    {
        "key": "zukuenftigeBrennstoffversorgung_mehrfamilienhaus",
        "name": "Gewünschte Heizart (Mehrfach Auswahl möglich)",
        "groupName": "Gebäude",
        "label": "Über welche Heiztechnologien wollen Sie sich informieren? Mehrfamilienhaus",
        "type": "iconMultiSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "DTH",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": "Bitte geben Sie an, mit welcher Energieart Ihre Heizung Sie zukünftig mit Wärme versorgen soll, damit wir eine passende Heizung für Sie finden."
        },
        "answers": [
            {
                "label": "Gas",
                "value": "gas",
                "icon": "G"
            },
            {
                "label": "Öl",
                "value": "oel",
                "icon": "O"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "=",
                "value": 2,
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 10
    },
    {
        "key": "zusatzSolar",
        "name": "Solarunterstützt",
        "groupName": "System künftig",
        "label": "Ist Solar als zusätzliche Wärmequelle gewünscht?",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "DTH",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": ""
        },
        "answers": [
            {
                "label": "Solarunterstützung",
                "value": true,
                "icon": "SO"
            }
        ],
        "conditions": [
            {
                "questionKey": "gebaeudeTyp",
                "operator": "contains",
                "value": [
                    1,
                    3
                ],
                "disable": false
            }
        ],
        "show": false,
        "b2cStep": 11
    },
    {
        "key": "umbauPlan",
        "name": "Zeitpunkt des Einbaus",
        "groupName": "System künftig",
        "label": "Wann soll der Umbau erfolgen?",
        "type": "iconSelect",
        "inputType": "text",
        "min": 0,
        "max": 0,
        "icon": "PLM",
        "system": [],
        "required": false,
        "suffix": "",
        "additionalTextInput": false,
        "information": {
            "title": "Was bedeutet das?",
            "text": ""
        },
        "answers": [
            {
                "label": "Sofort",
                "value": 1,
                "icon": "PL1"
            },
            {
                "label": "Diesen Monat",
                "value": 2,
                "icon": "PL2"
            },
            {
                "label": "In 1-2 Monaten",
                "value": 3,
                "icon": "PL3"
            },
            {
                "label": "Weiß ich noch nicht",
                "value": 4,
                "icon": "S"
            }
        ],
        "show": false,
        "b2cStep": 12
    }
];

export const testConditions = () => {
    // TODO test with equals as arary
    let result;
    // should be FALSE true if gebauedeTyp contains 1 or 3 and energieTraeger = 1
    let conds = questions[9].conditions; // contains and equal
    result = areConditionsTrue(conds, mock);
    console.log("Condition Test 1 passed: %s expected FALSE got %s",result==false,result);

    // should be FALSE true if gebauedeTyp = 2 and energieTraeger = 1
    conds = questions[10].conditions; // equal
    result = areConditionsTrue(conds, mock);
    console.log("Condition Test 2 passed: %s expected FALSE got %s",result==false,result);

    // should be FALSE true if gebauedeTyp contains 1 2  or 3 and energieTraeger = 1
    conds = questions[15].conditions; // contains and equal
    result = areConditionsTrue(conds, mock);
    console.log("Condition Test 3 passed: %s expected FALSE got %s",result==false,result);

    // should be TRUE true if gebauedeTyp contains 1 or 3 and energieTraeger = 1
    conds = questions[22].conditions; // contains and equal
    result = areConditionsTrue(conds, mock);
    console.log("Condition Test 1 passed: %s expected TRUE got %s",result==true,result);
}

export const testChangeResponseValues = () => {
    // example change 
    let newOption = {
        key: "energieTraeger",
        value: 4
    }
    let updatedResponseArray = getUpdatedResponseArrayAddOrChangeValue(newOption, mock,questions);
    console.log("Change Response Test 1, responseArray.length before: %s, responseArray.length after: %s", mock.length, updatedResponseArray.length);
    console.log(mock, updatedResponseArray);

    // test remove value
    // example change 
    newOption = {
        key: "energieTraeger",
        value: 1
    }
    updatedResponseArray = getUpdatedResponseArrayRemoveValue(newOption.key, mock,questions);
    console.log("Change Response Test 1, responseArray.length before SHOULD BE 11: is %s, responseArray.length SHOULD BE 6 after: is %s", mock.length, updatedResponseArray.length);
    console.log("Change Response Test 1 passed: %s", mock.length == 11&&  updatedResponseArray.length == 6);
}