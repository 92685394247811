import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap/dist/css/bootstrap.css';
import '../scoped-bootstrap.scss';
// import '../bootstrap-wrapper.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'animate.css/animate.css';
import "../style-elements.scss"
import "../style.scss"
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App/>, document.getElementById('root'));
