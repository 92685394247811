import {defaultConfig as defaultConfigFromDefault} from './defaultData/defaultConfig';

export const defaultConfig = {
    ...defaultConfigFromDefault,
    watchListUrl: '/api/universalbackend/public/api/heaterconfig/sendMail',
};

export const ELEMENTS_SHOW_VERSION=true;
export const IMAGE_ROOT_DIRECTORY="";
export const CONTACT_BY_FACHHANDWERKER_POSSIBLE=false;
export const SHOW_CONTACT_FORM = true;