import {ELEMENTS_SHOW_VERSION, IMAGE_ROOT_DIRECTORY} from "../../config";



const iconMapper = (iconValue) => {
    if (ELEMENTS_SHOW_VERSION) {
        // default path
        let path = IMAGE_ROOT_DIRECTORY+"/gc_icons/info.svg";
        switch (iconValue) {
            case "BT1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_Einfamilienhaus.svg';
                break;
            case "Y":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/ja.svg';
                break;
            case "N":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/nein.svg';
                break;
            case "BT2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_Doppelhaus.svg';
                break;
            case "BT3":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_Mehrfamilienhaus_L.svg';
                break;
            case "BT4":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_Wohnung_L.svg';
                break;
            case "RT1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_umgebung_haus_freistehend.svg';
                break;
            case "RT2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_umgebung_reihenhaus.svg';
                break;
            case "OR1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_verhaeltnis_eigentum.svg';
                break;
            case "OR2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_verhaeltnis_mieter.svg';
                break;
            case "REU1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_Einfamilienhaus.svg';
                break;
            case "REU2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_gewerblich.svg';
                break;
            case "LS1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_1.svg';
                break;
            case "LS2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_2.svg';
                break;
            case "LS3":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_3.svg';
                break;
            case "LS4":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_flaeche_4.svg';
                break;
            case "G":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_heizart_gas.svg';
                break;
            case "O":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_heizart_oel.svg';
                break;
            case "E":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_heizart_strom.svg';
                break;
            case "S":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_sonstiges.svg';
                break;
            case "P":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_heizart_pellets.svg';
                break;
            case "TOH1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_verteiler_heizkoerper.svg';
                break;
            case "TOH2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_verteiler_fussbodenheizung.svg';
                break;
            case "CH1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_standort_dachboden.svg';
                break;
            case "CH2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_standort_wohnraum.svg';
                break;
            case "CH3":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_standort_keller.svg';
                break;
            case "WH1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_warmwasser_heizungsanlage.svg';
                break;
            case "WH2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_warmwasser_durchlauferhitzer.svg';
                break;
            case "SO":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_heizart_solar.svg';
                break;
            case "PL1":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_kurzfristig.svg';
                break;
            case "PL2":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_mittelfristig.svg';
                break;
            case "PL3":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/Icon_konfigurator_zeitpunkt_langfristig.svg';
                break;
            case "BS":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/sacksilo.svg';
                break;
            case "PSR":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/lagerraum.svg';
                break;
            case "BV85":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/baujahr_vor85.svg';
                break;
            case "BN85":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/BN85.svg';
                break;
            case "BO":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/BO.svg';
                break;
            case "WA":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/WA.svg';
                break;
            case "ENT3":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/ENT_3m.svg';
                break;
            case "ENT8":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/ENT_8m.svg';
                break;
            case "ENT14":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/ENT_14m.svg';
                break;
            case "ENT20":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/ENT_20m.svg';
                break;
            case "PER12":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/PER_1-2.svg';
                break;
            case "PER23":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/PER_2-3.svg';
                break;
            case "PER45":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/PER_4-5.svg';
                break;
            case "PER6":
                path = IMAGE_ROOT_DIRECTORY+'/visuals/PER_6.svg';
                break;
            case "LW":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/lw.svg';
                break;
            case "BTM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/gebaeude_art.svg';
                break;
            case "RTM":
                break;
            case "ORM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/besitz_verhaeltnis.svg';
                break;
            case "REU":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/nutzung.svg';
                break;
            case "JA": path = IMAGE_ROOT_DIRECTORY+'/visuals/ja.svg';break;
            case "NEIN": path = IMAGE_ROOT_DIRECTORY+'/visuals/nein.svg';break;
            case "PH": path = IMAGE_ROOT_DIRECTORY+'/question_icons/ph.svg';break;
            case "LSM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/flaeche.svg';
                break;
            case "EFS":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/heizart.svg';
                break;
            case "CHM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/aufstell_ort.svg';
                break;
            case "TOHM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/heizung.svg';
                break;
            case "WHM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/warmwasser_bereitung.svg';
                break;
            case "DTH":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/heizart.svg';
                break;
            case "PLM":
                path = IMAGE_ROOT_DIRECTORY+'/question_icons/zeitpunkt.svg';
                break;


            default:
                break;
        }
        return path;
    }
    else {
        {
            // default path
            let path = "./gc_icons/info.svg";
            switch (iconValue) {
                case "BT1": path = './visuals/Icon_Einfamilienhaus.svg';break;
                case "BT2": path = './visuals/Icon_Doppelhaus.svg';break;
                case "BT3": path = './visuals/Icon_Mehrfamilienhaus_L.svg';break;
                case "BT4": path = './visuals/Icon_Wohnung_L.svg';break;
                case "RT1": path = './visuals/Icon_konfigurator_umgebung_haus_freistehend.svg';break;
                case "RT2": path = './visuals/Icon_konfigurator_umgebung_reihenhaus.svg';break;
                case "OR1": path = './visuals/Icon_konfigurator_verhaeltnis_eigentum.svg';break;
                case "OR2": path = './visuals/Icon_konfigurator_verhaeltnis_mieter.svg';break;
                case "REU1": path = './visuals/Icon_Einfamilienhaus.svg';break;
                case "REU2": path = './visuals/Icon_konfigurator_zeitpunkt_gewerblich.svg';break;
                case "LS1": path = './visuals/Icon_konfigurator_flaeche_1.svg';break;
                case "LS2": path = './visuals/Icon_konfigurator_flaeche_2.svg';break;
                case "LS3": path = './visuals/Icon_konfigurator_flaeche_3.svg';break;
                case "LS4": path = './visuals/Icon_konfigurator_flaeche_4.svg';break;
                case "G": path = './visuals/Icon_konfigurator_heizart_gas.svg';break;
                case "O": path = './visuals/Icon_konfigurator_heizart_oel.svg';break;
                case "E": path = './visuals/Icon_konfigurator_heizart_strom.svg';break;
                case "S": path = './visuals/Icon_konfigurator_sonstiges.svg';break;
                case "P": path = './visuals/Icon_konfigurator_heizart_pellets.svg';break;
                case "TOH1": path = './visuals/Icon_konfigurator_verteiler_heizkoerper.svg';break;
                case "TOH2": path = './visuals/Icon_konfigurator_verteiler_fussbodenheizung.svg';break;
                case "CH1": path = './visuals/Icon_konfigurator_standort_dachboden.svg';break;
                case "CH2": path = './visuals/Icon_konfigurator_standort_wohnraum.svg';break;
                case "CH3": path = './visuals/Icon_konfigurator_standort_keller.svg';break;
                case "WH1": path = './visuals/Icon_konfigurator_warmwasser_heizungsanlage.svg';break;
                case "WH2": path = './visuals/Icon_konfigurator_warmwasser_durchlauferhitzer.svg';break;
                case "SO": path = './visuals/Icon_konfigurator_heizart_solar.svg';break;
                case "PL1": path = './visuals/Icon_konfigurator_zeitpunkt_kurzfristig.svg';break;
                case "PL2": path = './visuals/Icon_konfigurator_zeitpunkt_mittelfristig.svg';break;
                case "PL3": path = './visuals/Icon_konfigurator_zeitpunkt_langfristig.svg';break;
                case "BS": path = './visuals/sacksilo.svg';break;
                case "PSR": path = './visuals/lagerraum.svg';break;
                case "Y": path = './visuals/ja.svg';break;
                case "N": path = './visuals/nein.svg';break;
                case "JA": path = './visuals/ja.svg';break;
                case "NEIN": path = './visuals/nein.svg';break;
                case "BO":
                    path = './visuals/BO.svg';
                    break;
                case "WA":
                    path = './visuals/WA.svg';
                    break;
                case "BV85":
                    path = './visuals/baujahr_vor85.svg';
                    break;
                case "BN85":
                    path = './visuals/BN85.svg';
                    break;
                case "BTM": path = './question_icons/gebaeude_art.svg';break;
                case "PH": path = './question_icons/ph.svg';break;
                case "RTM": break;
                case "ORM": path = './question_icons/besitz_verhaeltnis.svg';break;
                case "REU": path = './question_icons/nutzung.svg';break;
                case "LSM": path = './question_icons/flaeche.svg';break;
                case "LW": path = './question_icons/lw.svg';break;
                case "EFS": path = './question_icons/heizart.svg';break;
                case "CHM": path = './question_icons/aufstell_ort.svg';break;
                case "TOHM": path = './question_icons/heizung.svg';break;
                case "WHM": path = './question_icons/warmwasser_bereitung.svg';break;
                case "DTH": path = './question_icons/heizart.svg';break;
                case "PLM": path = './question_icons/zeitpunkt.svg';break;
                case "ENT3":
                    path = './visuals/ENT_3m.svg';
                    break;
                case "ENT8":
                    path = './visuals/ENT_8m.svg';
                    break;
                case "ENT14":
                    path = './visuals/ENT_14m.svg';
                    break;
                case "ENT20":
                    path = './visuals/ENT_20m.svg';
                    break;
                case "PER12":
                    path = './visuals/PER_1-2.svg';
                    break;
                case "PER23":
                    path = './visuals/PER_2-3.svg';
                    break;
                case "PER45":
                    path = './visuals/PER_4-5.svg';
                    break;
                case "PER6":
                    path = './visuals/PER_6.svg';
                    break;



                default:break;
            }
            return path;
        }
    }

    }


export default iconMapper;