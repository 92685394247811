import React, {useContext, useEffect, useState} from 'react';
import 'url-search-params-polyfill'; // for IE
import {CustomForm, Loader, QuestionsPage} from './components';
import {Reveal} from 'react-reveal';
import {WelcomePage} from './components/WelcomePage';
import {ResultsPage} from './components/ResultsPage';
import {ResultOverview} from './components/ResultOverview';
import {useMediaQuery} from 'react-responsive';
import GlobalContextProvider, {GlobalContext} from './context';
import ProgressBar from './components/ProgressBar';
import Footer from './components/Footer';
import {defaultConfig} from './config';
import {testChangeResponseValues, testConditions} from './tests';

export const App = () => {

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 999px)'});
    const [isTenant, setIsTenant] = useState(false);

    const {
        currentPageIndex,
        lastPageIndex,
        responseArray,
        pending,
        showForm,
        pages,
        showWelcomePage,
        showResultsPage,
        clickStart,
        handleUpdateResponses,
        goToContactForm,
        goToResults,
        handleSelectAnswer,
        handleGoBack,
        handleSubmitForm,
        virtualPaginationSize,
        resultOverviewExpandedMobile,
        expandResultOverviewMobile,
    } = useContext(
        GlobalContext);

    const renderForm = () => {
        return (
            <CustomForm
                handleSubmitForm={handleSubmitForm}
                handleBackToResults={goToResults}
            />
        );
    };

    const showResults = () => {
        return (<React.Fragment>
            <ResultOverview
                responses={responseArray}
                questionsArray={pages}
                isTabletOrMobile={isTabletOrMobile}
                handleUpdateResponses={(responses) => {
                    handleUpdateResponses(responses);
                }}
                openOnMobile={(state) => {
                    expandResultOverviewMobile(state);
                    window.scrollTo(0, 0);
                }}/>
            {isTabletOrMobile && !resultOverviewExpandedMobile &&
            <ResultsPage
                isTabletOrMobile={isTabletOrMobile}
                goToContactForm={(productInfo) => goToContactForm(productInfo)}
                responses={responseArray}/>}
            {!isTabletOrMobile && (
                <ResultsPage
                    isTabletOrMobile={isTabletOrMobile}
                    goToContactForm={(productInfo) => goToContactForm(productInfo)}
                    responses={responseArray}/>
            )}
        </React.Fragment>);
    };

    const renderPages = () => {
        const content = pages.map((page, index) => {
            // get selectedQuestion(s)
            const questionResponse = responseArray.find((obj) => {
                return obj.key === page.key;
            });
            //console.log(responseArray);
            //console.log(questionResponse, responseArray, "respones array and question resüosne");
            let values = null;
            if (questionResponse) {
                // response exists already, pass them
                values = questionResponse.value;
            }
            return (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        //height:'100%',
                        height: currentPageIndex === index ? '100%' : '0',
                        //visibility: currentPageIndex === index ? 'visible' : 'hidden',
                        opacity: currentPageIndex === index ? 1 : 0,
                        //display: currentPageIndex === index ? 'block' : 'none',
                        //height: currentPageIndex === index ? 'auto': '0',
                        overflow: 'hidden',
                        transition: 'opacity 1s, height 0s 0.3s',
                        //transition: 'height 1s'
                    }}
                    key={index}
                >
                    <Reveal
                        effect={currentPageIndex >= lastPageIndex ?
                            'fadeInRight' :
                            'fadeInLeft'}
                        effectOut={currentPageIndex >= lastPageIndex ?
                            'fadeOutLeft' :
                            'fadeOutRight'}
                        when={currentPageIndex === index}
                    >
                        <QuestionsPage
                            isTabletOrMobile={isTabletOrMobile}
                            questions={page.answers}
                            title={page.label}
                            infoBoxLabel={page.information ? page.information.title : ''}
                            infoBoxText={page.information ? page.information.text : ''}
                            selectAnswer={(answer, key) => {
                                console.log('ANSWER', answer);
                                console.log('ANSWER key', key);
                                if (key === 'Mieter') {
                                    console.log('STOP HERE');
                                    setIsTenant(true);
                                } else {
                                    handleSelectAnswer(answer)
                                }
                            }}
                            selectedQuestionFromPage={values}
                            currentPageIndex={currentPageIndex}
                            virtualPaginationSize={virtualPaginationSize}
                            handleGoBack={handleGoBack}
                            isMultiSelect={page.type === 'iconMultiSelect'}
                        />
                    </Reveal>
                </div>
            );
        });

        return <div style={{
            position: 'relative',
            width: '100%',
            height: '100vh',
        }}>{content}</div>;
    };

    const showProgressBar = !showForm && !showWelcomePage && !showResultsPage;

    if (pending) {
        return <Loader/>;
    }
    let content = renderPages();

    if (showWelcomePage) {
        content = <WelcomePage onStartClick={clickStart} isTabletOrMobile={isTabletOrMobile}/>;
    } else if (showForm) {
        content = renderForm();
        return (
            <div>
                <div
                    id="maincontainer"
                    className={isTabletOrMobile ?
                        'sm-view container col-lg-10 col-12' :
                        'container col-lg-10 col-12'}>
                    <>
                        {showProgressBar &&
                        <ProgressBar isTabletOrMobile={isTabletOrMobile}/>}
                        {content}
                    </>
                    <Footer/>
                </div>
            </div>
        );
    } else if (showResultsPage) {
        content = showResults();
        return (
            <div>
                <div
                    id="maincontainer"
                    className={isTabletOrMobile ?
                        'sm-view container col-lg-10 col-12' :
                        'container col-lg-10 col-12'}>
                    <>
                        {showProgressBar &&
                        <ProgressBar isTabletOrMobile={isTabletOrMobile}/>}
                        {content}
                    </>
                    <Footer/>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div
                className={isTabletOrMobile ?
                    'sm-view container col-lg-10 col-12' :
                    'container col-lg-10 col-12'}>
                <>
                    {!isTenant ? (
                        <>
                            {showProgressBar &&
                            <ProgressBar isTabletOrMobile={isTabletOrMobile}/>}
                            {content}
                        </>) : (
                        <div style={{
                            marginBottom: 20, display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <div style={{marginTop: 20, marginBottom: 20}}>Bitte wenden Sie sich bzgl. Ihrer Heizungssanierung an
                                den
                                Eigentümer der Immobilie.
                            </div>

                            <button className="buttonHighlight" onClick={() => setIsTenant(false)}>Zurück
                            </button>

                        </div>)
                    }
                </>
                <Footer/>
            </div>
        </div>
    );
};

export default () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const configUrl = params.get('configUrl');

    const [configuration, setConfiguration] = useState(null);
    const [key] = useState(null); // Maybe needs to be injected in Service
    const [configId, setConfigId] = useState(null);

    // TURNING OFF ALL CONSOLE LOGS
    console.log = function () {
    };
    // TESTS
    testConditions();
    testChangeResponseValues();
    useEffect(() => {
        (async () => {
            if (configUrl) {
                const result = await fetch(configUrl, {
                    method: 'get',
                    headers: {'Content-Type': 'application/json'},
                });
                const remoteConfiguration = await result.json();
                setConfiguration(remoteConfiguration);
                setConfigId(remoteConfiguration.id);
            } else {
                const fixedConfigData = params.get('configData');
                if (fixedConfigData) {
                    const defaultConfig = JSON.parse(fixedConfigData);
                    setConfigId(defaultConfig.id);
                    setConfiguration(defaultConfig);
                }
                else {
                    setConfiguration(defaultConfig);
                }
            }
        })();
    }, []);

    // Overwrite colors from remote
    useEffect(() => {
        if (configuration) {
            let root = document.documentElement;
            root.style.setProperty('--highlight-color-1',
                configuration.colors.highlight1);
            root.style.setProperty('--highlight-color-2',
                configuration.colors.highlight2);
            root.style.setProperty('--background-color',
                configuration.colors.background);
            root.style.setProperty('--accent-color-1',
                configuration.colors['accent_1']);
            root.style.setProperty('--accent-color-2',
                configuration.colors['accent_2']);
            root.style.setProperty('--accent-color-3',
                configuration.colors['accent_3']);
            root.style.setProperty('--font-color', configuration.colors.font);
        }
    }, [configuration]);

    if (!configuration) {
        return <div className="spinner">
            <div className="double-bounce1"/>
            <div className="double-bounce2"/>
        </div>;
    }

    // set config id
    configuration.id = configId;

    return (
        <GlobalContextProvider config={configuration}>
            <App/>
        </GlobalContextProvider>
    );
};
