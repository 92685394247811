import SVG from 'react-inlinesvg';
import React, {useContext, useEffect, useReducer} from 'react';
import {GlobalContext} from '../../context';
import {initialState, reducer} from '../../context/reducer';
import $ from 'jquery';
import {areConditionsTrue} from '../../utils';
import Icons from "../../icons";

const ProgressBar = (isTabletOrMobile) => {

  const {handleGoBack, goToNextQuestion, responseArray, currentPageIndex, virtualPaginationSize, pages, handleGoToPage, isDarkMode} = useContext(GlobalContext);
  const [state, dispatch] = useReducer(reducer, initialState);


  // get current question
  let required = pages[currentPageIndex] ? pages[currentPageIndex].required : false;
  let enableNextButton = true;
  if (required) {
    // check if there is any response already for this question
    enableNextButton = responseArray.findIndex((obj) => {
      return obj.key == pages[currentPageIndex].key
    }) == -1 ? false : true;
  }


  const renderProgressBar = () => {
    // create progress
    let progressBars = [];
    // check all questions for conditions
    let pagesToRender = [];
    // loop over conditions for this page
    for (let i = 0; i < pages.length; i++) {
      // check if page has condition
      if (pages[i].conditions) {
        if (areConditionsTrue(pages[i].conditions, responseArray)) {
          pagesToRender.push(pages[i]);
        }
      } else {
        // no condition, render page for sure
        pagesToRender.push(pages[i]);
      }
    }
    // get width of 1 element
    let widthElement = 100 / pagesToRender.length;
    // render unselected
    for (let i = 0; i < virtualPaginationSize; i++) {
      let showcondition = false;
      // check for active element
      if (i == currentPageIndex) {
        let element = (<div
            key={i}
            className={i == currentPageIndex ? "progress-bar-active" : "progress-bar"}
            role="progressbar"
            data-original-title=""
            style={{
              width: widthElement + '%',
              opacity: (i < currentPageIndex || i == currentPageIndex) ? 1 : 0
            }}
        />);
        progressBars.push(element);
        break;
      }
      if ((i < currentPageIndex)) {
        // check if condition is true for this question
        if (pages[i].conditions) {
          showcondition = areConditionsTrue(pages[i].conditions, responseArray);
          if (showcondition) {
            progressBars.push(<div
                key={i}
                className={"progress-bar"}
                role="progressbar"
                style={{
                  width: widthElement + '%',
                  opacity: (i < currentPageIndex) ? 1 : 0
                }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                data-original-title={pages[i].name}
                data-template={isDarkMode ?
                    '<div class="tooltip" role="tooltip"><div class="arrow custom-tooltip-arrow"></div><div class="tooltip-inner custom-tooltip-dark"></div></div>' :
                    '<div class="tooltip" role="tooltip"><div class="arrow custom-tooltip-arrow"></div><div class="tooltip-inner custom-tooltip"></div></div>'}
                data-html="true"
                data-toggle="tooltip"
                data-placement="top" title={pages[i].name}
                onClick={() => handleGoToPage(i)}
            />);
          }
        } else {
          progressBars.push(<div
              key={i}
              className={"progress-bar"}
              role="progressbar"
              style={{
                width: widthElement + '%',
                opacity: (i < currentPageIndex) ? 1 : 0
              }}
              data-original-title={pages[i].name}
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
              data-template={isDarkMode ?
                  '<div class="tooltip" role="tooltip"><div class="arrow custom-tooltip-arrow"></div><div class="tooltip-inner custom-tooltip-dark"></div></div>' :
                  '<div class="tooltip" role="tooltip"><div class="arrow custom-tooltip-arrow"></div><div class="tooltip-inner custom-tooltip"></div></div>'}
              data-html="true"
              data-toggle="tooltip"
              data-placement="top" title={pages[i].name}
              onClick={() => handleGoToPage(i)}
          />);
        }
      }
    }
    return progressBars;
  }
  // enable boostrap tooltips with jquery
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    console.log("now");
  }, [renderProgressBar])
  return (
      <div className="paginationContainer">
        <div className="progressBarContainer">
          <div className="arrowContainer">
          <span className="pointer arrowContainerContent"
                onClick={() => handleGoBack(null)}>
            <SVG src={Icons.arrow_left} width="23"
                 height="16"/>
                        Zurück
                    </span>

            <span className={"pointer arrowContainerContent " + (enableNextButton ? "" : "disabledNext")}
                  onClick={() => {
                    enableNextButton ? goToNextQuestion(null, responseArray) : () => {
                    }
                  }}>
            Weiter
                        <SVG src={Icons.arrow_right} width="23"
                             height="16"/>
          </span>
            {enableNextButton}
          </div>
          {isTabletOrMobile.isTabletOrMobile == true && (
              <div className="progress" style={{flex: 1, height: "25px"}}>
                {renderProgressBar()}
              </div>
          )}
          {isTabletOrMobile.isTabletOrMobile == false && (
              <div className="progress" style={{flex: 1}}>
                {renderProgressBar()}
              </div>
          )}
        </div>
      </div>
  );
};

export default ProgressBar;
