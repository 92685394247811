import React from 'react';
import SVG from 'react-inlinesvg';
import imageMapper from '../ImageMapper';
import {ELEMENTS_SHOW_VERSION} from "../../config";
import iconMapper from "../IconMapper";
import Icons from "../../icons";


export const Question = ({id, icon, image, text, selectAnswer, isSelected, isTabletOrMobile}) => {

    const getClassname = () => {
        let base = 'paper';
        if (isTabletOrMobile) {
            base += ' question question-mobile';
        } else {
            base += ' question';
        }

        if (isSelected) {
            base += ' questionSelected'
        }
        return base;
    }

    const getContainerClassname = () => {
        let base = 'paper-container';
        if (isTabletOrMobile) {
            base += ' mobile';
        }

        return base;
    }

    const isNewIcon = (icon, text) => {

        if (text === 'Ja' || text === 'Nein') {
            return true;
        }

        switch (icon) {
            case 'BN85':
                return true;
            case 'BV85':
                return true;
            case 'WA':
                return true;
            case 'BO':
                return true;
            case 'ENT3':
                return true;
            case 'ENT8':
                return true;
            case 'ENT14':
                return true;
            case 'ENT20':
                return true;
            case 'PER12':
                return true;
            case 'PER23':
                return true;
            case 'PER45':
                return true;
            case 'PER6':
                return true;
            default:
                switch (text) {
                    case '3m':
                        return true;
                    case '8m':
                        return true;
                    case '14m':
                        return true;
                    case '20m':
                        return true;
                    default:
                        return false;
                }
        }
    }

    var size = isTabletOrMobile ? "80" : "130"

    return (
        <div className={getContainerClassname()}>
            {isSelected && <span className="selected-indicator">
                <span className={"indicator-icon"}/>
        </span>}
            <div className={getClassname()}
                 onClick={() => selectAnswer(id)}
                 id={id}>

                {(ELEMENTS_SHOW_VERSION ?
                    ((!imageMapper(icon) || text === 'Ja' || text === 'Nein') ?
                    <span className={'question-svg-container'}> <SVG src={
                    text === 'Ja' ? Icons.question_yes
                    :
                    text === 'Nein' ? Icons.question_no :
                    text === '3m' ? Icons.question_3m : text === '8m' ? Icons.question_8m : text === '14m' ? Icons.question_14m : text === '20m' ? Icons.question_20m :
                    iconMapper(icon)
                } width="100%" height={isNewIcon(icon, text) ? '100%' : '75%'}
                    fill={isNewIcon(icon, text) ? 'white' : undefined}/></span>
                    :
                    <img className={'question-image'} src={imageMapper(icon)} alt={text}/>)
                    : null)
                }

                {!ELEMENTS_SHOW_VERSION && <SVG src={iconMapper(icon)} width={size} height={size}/>}


                <span className="description">{text}</span>
            </div>
        </div>
    );
};
